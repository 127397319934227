import React from 'react';
import AutoPlay from './autoplay';
import Legend from './Legend';
import {vidQuality} from '../App';

/* eslint-disable no-dupe-keys */

function ComparisonWidget(props) {
    return (<>

        <div className="each-image"
            style={{ position: 'relative' }}
            onDragStart={e => e.preventDefault()}
            onTouchStart={(i) => {
                props.setIsDraggingCompare([true, false]);
                i.preventDefault();
            }}
            onMouseDown={(i) => {
                props.setIsDraggingCompare([true, false]);
                i.preventDefault();
            }}
            onMouseUp={() => props.setIsDraggingCompare([false, false])}
            onTouchEnd={() => props.setIsDraggingCompare([false, false])}
            onTouchMove={(i) => {
                i.persist()
                if (props.isDraggingCompare[0]) {
                    if (props.isDraggingCompare[1]) {
                        props.setCompareSize(x => i.touches[0].clientX - props.isDraggingCompare[1])
                    } else {
                        props.setIsDraggingCompare([true, i.touches[0].clientX - props.compareSize])
                    }
                }
                i.preventDefault();
            }}
            onMouseMove={(i) => {
                i.persist()
                if (props.isDraggingCompare[0]) {
                    if (props.isDraggingCompare[1]) {
                        props.setCompareSize(x => i.clientX - props.isDraggingCompare[1])
                    } else {
                        props.setIsDraggingCompare([true, i.clientX - props.compareSize])
                    }
                }
                i.preventDefault();
            }}>
            <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
                <div style={{
                    display: '-webkit-box',
                    display: '-moz-box',
                    display: '-ms-flexbox',
                    display: '-webkit-flex',
                    display: 'flex', width: '100%', height: '100%',
                }}>
                    {props.leftSimple ?
                        <div style={{
                            height: '100%', width: 'calc(50% + ' + props.compareSize + 'px)', overflow: 'hidden'
                        }}>
                            <div>
                                <AutoPlay show={props.show} src={'./images/videos/' + props.leftImages + 'Video' + (props.videoID + 1) + '_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.leftImages + 'Video' + (props.videoID + 1) + '/Frame1.jpg'} imageOnly={props.imageOnly} />
                            </div>
                        </div>
                    :
                        <div style={{
                                height: '100%', width: 'calc(50% + ' + props.compareSize + 'px)', overflow: 'hidden'
                        }}>
                            <div>
                                <AutoPlay show={props.show && props.resID === 0} src={'./images/videos/' + props.leftImages + 'Video' + (props.videoID + 1) + '_Res0_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.leftImages + 'Video' + (props.videoID + 1) + '_Res0/Frame1.jpg'} imageOnly={props.imageOnly} />
                                <AutoPlay show={props.show && props.resID === 1} src={'./images/videos/' + props.leftImages + 'Video' + (props.videoID + 1) + '_Res1_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.leftImages + 'Video' + (props.videoID + 1) + '_Res1/Frame1.jpg'} imageOnly={props.imageOnly} />
                                <AutoPlay show={props.show && props.resID === 2} src={'./images/videos/' + props.leftImages + 'Video' + (props.videoID + 1) + '_Res2_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.leftImages + 'Video' + (props.videoID + 1) + '_Res2/Frame1.jpg'} imageOnly={props.imageOnly} />
                            </div>
                        </div>
                    }
                    <div style={{
                        display: '-webkit-box',
                        display: '-moz-box',
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex', justifyContent: 'flex-end', height: '100%', width: 'calc(50% - ' + props.compareSize + 'px)', overflow: 'hidden'
                    }}>
                        <div>
                            <AutoPlay show={props.show && props.resID === 0} src={'./images/videos/' + props.rightImages + 'Video' + (props.videoID + 1) + '_Res0_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.rightImages + 'Video' + (props.videoID + 1) + '_Res0/Frame1.jpg'} imageOnly={props.imageOnly}  />
                            <AutoPlay show={props.show && props.resID === 1} src={'./images/videos/' + props.rightImages + 'Video' + (props.videoID + 1) + '_Res1_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.rightImages + 'Video' + (props.videoID + 1) + '_Res1/Frame1.jpg'} imageOnly={props.imageOnly}  />
                            <AutoPlay show={props.show && props.resID === 2} src={'./images/videos/' + props.rightImages + 'Video' + (props.videoID + 1) + '_Res2_' + vidQuality + '.mp4'} imageSrc={'./images/' + props.rightImages + 'Video' + (props.videoID + 1) + '_Res2/Frame1.jpg'} imageOnly={props.imageOnly}  />
                        </div>
                    </div>
                </div>
            </div>
            <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
                <div style={{
                    display: '-webkit-box',
                    display: '-moz-box',
                    display: '-ms-flexbox',
                    display: '-webkit-flex',
                    display: 'flex', width: 'calc(100% + 3rem)', transform: 'translateX(-1.5rem)', height: '100%',
                }}>
                    <div style={{ height: '100%', width: 'calc(50% - 1.5rem + ' + props.compareSize + 'px)' }} />
                    <div style={{
                        height: '100%', width: '3rem', display: '-webkit - box',
                        display: '-webkit-box',
                        display: '-moz-box',
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex', }}>
                        <div style={{
                            display: '-webkit-box',
                            display: '-moz-box',
                            display: '-ms-flexbox',
                            display: '-webkit-flex',
                            display: 'flex',
                            width: '100%', height: '100%', justifyContent: 'center',
                            flexDirection: 'column'
                        }}>
                            <div
                                style={{ height: '3rem', borderRadius: '3rem', backgroundColor: props.lineColor }} />
                        </div>
                        <div style={{ backgroundColor: props.lineColor, width: '5px', transform: 'translateX(-1.5rem)' }} />
                    </div>
                </div>
            </div>
            <div style={{ height: '100%', width: '100%', position: 'absolute' }}>
                <Legend nodeName={props.leftName} side={"left"} tour={props.tour} orientation={props.orientation} metresText={props.panelTexts.extras.metres} mainText={props.panelTexts[props.leftName].legend} />
                <Legend nodeName={props.rightName} side={"right"} tour={props.tour} orientation={props.orientation} metresText={props.panelTexts.extras.metres} mainText={props.panelTexts[props.rightName].legend} />
            </div>
        </div>

        <div style={{
            display: '-webkit-box',
            display: '-moz-box',
            display: '-ms-flexbox',
            display: '-webkit-flex',
            display: 'flex', width: '100%'}}>
            <p style={{
                fontSize: '1.5rem',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
            }}>{props.leftText}</p>
            <div style={{ flexGrow: 1 }} />
            <p style={{
                fontSize: '1.5rem',
                marginTop: '0.5rem',
                marginBottom: '0.5rem',
            }}>{props.rightText}</p>
        </div>
    </>)

}

export default ComparisonWidget;
