import React from 'react';
import "../../node_modules/video-react/dist/video-react.css";
import Slider from '@material-ui/core/Slider';
import ComparisonWidget from './ComparisonWidget';
import AutoPlay from './autoplay';
import Legend from './Legend'
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ReactGA from 'react-ga';

/* eslint-disable no-dupe-keys */
function ImageVideoSelector({ imageOnly, setImageOnly, tour, videoT, imageT, canToggleImages }) {
    return tour || canToggleImages ? '' : (
        <RadioGroup aria-label="gender" name="gender1" value={imageOnly} onChange={i => setImageOnly(i.target.value === 'true')} style={{ flexDirection: 'row', color: 'white', display: tour ? "none" : "", }}>
            <FormControlLabel style={{ flexGrow: 1 }} value={false} control={<Radio />} label={videoT}
                onClick={() => {
                    console.log('video mode GA')
                    ReactGA.event({
                        category: "mode switch",
                        action: "video",
                    })
                }
                }
            />
            <FormControlLabel style={{ flexGrow: 2 }} value={true} control={<Radio />} label={imageT}
                onClick={() => ReactGA.event({
                    category: "mode switch",
                    action: "image",
                })}
            />
        </RadioGroup>
    )
}

function PredictedDepthText({ onTour, imageOnly, setImageOnly, tour, text, imageT, videoT, canToggleImages }) {
    switch (onTour) {
        case true:
            return (
                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={imageOnly} setImageOnly={setImageOnly} canToggleImages={canToggleImages} tour={tour} imageT={imageT} videoT={videoT} />
                    <p style={{
                        fontSize: '1rem',
                    }}>
                        {text.p1_1}
                        </p>
                    <p style={{
                        fontSize: '1rem',
                    }}>
                        {text.p2_1}
                        </p>
                </div>
            )
        case false:
            return (
                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={imageOnly} setImageOnly={setImageOnly} canToggleImages={canToggleImages} tour={tour} imageT={imageT} videoT={videoT}/>
                    <p style={{
                        fontSize: '1rem',
                    }}>
                        {text.p1_2}
                    </p>
                    <p style={{
                        fontSize: '1rem',
                    }}>
                        {text.p2_2}
                    </p>
                </div>
            )
        default:
            return (
                <div className="below-image-div" />
            )
    }
}

function InputDepthText({ onTour, imageOnly, setImageOnly, tour, resID, handleSliderChange, text, imageT, videoT, canToggleImages }) {
    switch (onTour) {
        case true:
            return (
                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={imageOnly} setImageOnly={setImageOnly} canToggleImages={canToggleImages} tour={tour} imageT={imageT} videoT={videoT}/>
                    <p style={{
                        color: 'white',
                        marginBottom: '0',
                        fontSize: '1rem',
                        lineHeight: '1.8',
                    }}>
                        {text.p1_1}
                    </p>
                    <p style={{
                        color: 'white',
                        marginBottom: '0',
                        fontSize: '1rem',
                        lineHeight: '1.8',
                        fontWeight: 'bold',
                    }}>{text.p2_1}</p>
                </div>
            )
        case false:
            return (
                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={imageOnly} setImageOnly={setImageOnly} canToggleImages={canToggleImages} tour={tour} imageT={imageT} videoT={videoT}/>
                    <p className="resolution-dictionary" style={{
                        color: 'white',
                    }}>
                        {text["e" + (resID + 1).toString()]}
                    </p>
                    <p style={{
                        color: 'white',
                        marginBottom: '0.6rem',
                    }}>
                        {text.p1_2}
                    </p>
                    <div className="hide-in-landscape" style={{ height: "1.5vw" }} />
                    <div style={{
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex', flexDirection: 'column', alignItems: 'center'
                    }}>
                        <Slider
                            style={{ width: 'calc(100% - 6rem)' }}
                            value={2 - resID}
                            marks={[
                                {
                                    value: 0,
                                    label: <div>{text.r3}<br />{text.c3}</div>
                                },
                                {
                                    value: 1,
                                    label: <div>{text.r2}<br />{text.c2}</div>
                                },
                                {
                                    value: 2,
                                    label: <div>{text.r1}<br />{text.c1}</div>
                                }
                            ]}
                            min={0}
                            max={2}
                            onChange={handleSliderChange}
                        />
                    </div>
                    <p style={{
                        color: 'white',
                        marginBottom: '0',
                        fontSize: '1rem',
                        lineHeight: '1.8',
                    }}>
                        {text.p2_2}<br />
                    </p>
                    <p style={{
                        color: 'white',
                        marginBottom: '0',
                        fontSize: '1rem',
                        lineHeight: '1.8',
                    }}>
                        {text.p3_2}
                    </p>
                </div>
            )
        default:
            return (
                <div className="below-image-div" />
            )
    }
}

function Panels(props) {

    const handleSliderChange = (event, value) => {
        props.setResID(2-value);
    };

    const handleBackSelect = () => {
        var val = props.videoID - 1;
        val = (val < 0) ? 2 : val;
        val = (val > 2) ? 0 : val;
        props.setVideoID(val);
        //props.setFrameID(0);
        props.loadImages(val, props.setImages);
    }

    const handleForwardSelect = () => {
        var val = props.videoID + 1;
        val = (val < 0) ? 2 : val;
        val = (val > 2) ? 0 : val;
        props.setVideoID(val);
        //props.setFrameID(0);
        props.loadImages(val, props.setImages);
    }

    const isAnimating = props.puzzleAnimationState.isAnimating

    return (<>
        {!isAnimating ? '' : <div style={{
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
        }}>
            <div className="filler" />
            <div className="above-image-div">
                <div style={{ flexGrow: 1 }} />
                <p className="non-legend-title" style={{
                    margin: '0 0 0 0',
                }}>{props.titles.proc}</p>
                <p style={{ marginTop: '1rem', fontSize: '1rem' }}>
                    {props.panelTexts.proc.p1}
                </p>
                <p style={{ marginTop: '1rem', fontSize: '1rem' }}>
                    {props.panelTexts.proc.p2}
                </p>
            </div>
            <div className="filler" />
        </div>}

        {isAnimating?'':<div style={{display: isAnimating ? 'none' : ''}}>
            {props.graphState.currentNode === 'begin'?<div style={{
                height: '100%',
                alignItems: 'center',
                display: props.graphState.currentNode === "begin" ? 'flex' : 'none',
                display: props.graphState.currentNode === "begin" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "begin" ? '-webkit-flex' : 'none',
                flexDirection: 'column',
            }}>
                <div className="filler" />
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p className="non-legend-title" style={{
                        margin: '0 0 0 0',
                    }}>{props.titles.tour}</p>
                    <p style={{ marginTop: '1rem', fontSize: '1rem' }}>
                        {props.panelTexts.tour.p1}
                    </p>
                    <p style={{ marginTop: '1rem', fontSize: '1rem' }}>
                        {props.panelTexts.tour.p2}
                    </p>
                    <p style={{ marginTop: '1rem', fontSize: '1rem' }}>
                        {props.panelTexts.tour.p3}
                    </p>
                </div>
                <div className="filler" />
            </div>:''}

            {props.graphState.currentNode==="scene"?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "scene" ? 'flex' : 'none',
                display: props.graphState.currentNode === "scene" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "scene" ? '-webkit-flex' : 'none',
                flexDirection: 'column',
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p className="non-legend-title" style={{
                        margin: '0 0 0 0',
                    }}>{props.titles.scene}</p>
                    <p className="hide-in-portrait" style={{ marginTop: '0.4rem', marginBottom: '1rem', fontSize: '1rem' }}>
                        {props.panelTexts.scene.p1}
                    </p>
                    <p className="hide-in-landscape" style={{ marginTop: '0.4rem', marginBottom: '1rem', fontSize: '1rem' }}>
                        {props.panelTexts.scene.p2}
                    </p>
                </div>

                <AutoPlay show={props.graphState.currentNode === "scene"} src={'./images/videos/RGBVideo' + (props.videoID + 1) + '_mid.mp4'} imageSrc={'./images/RGBVideo' + (props.videoID + 1) + '/Frame1.jpg'} imageOnly={props.imageOnly} />

                <div className="below-image-div" style={{
                    display: '-webkit-box',
                    display: '-moz-box',
                    display: '-ms-flexbox',
                    display: '-webkit-flex',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <ImageVideoSelector imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} canToggleImages={props.canToggleImages} tour={props.tour} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video}/>
                    <div className="filler hide-in-portrait" />
                    <div className="hide-in-landscape" style={{height: '4rem'}}/>
                    <div className="scene-selection-container">
                        <button onClick={handleBackSelect} className="scene-selection-buttons">
                            <div className="arrow">
                                <img className="arrow-image" src={"./images/BackArrow.svg"} alt="left arrow"/>
                            </div>
                        </button>
                        <div style={{ flexGrow: 1 }} />
                        <button onClick={() => {
                            if (props.videoID !== 0) {
                                //props.setFrameID(0);
                                props.setVideoID(0);
                                props.loadImages(0, props.setImages);
                            }
                        }} className="scene-selection-buttons">
                            <img className="scene-select-image" src={props.rgbVideos[0][0]} alt="1st vid" style={{
                                outlineStyle: 'solid',
                                outlineColor: '#20C1BB',
                                outlineWidth: (props.videoID === 0) ? (((window.innerWidth / window.innerHeight) < (299 / 211)) ? '1vw' : '1vh') : '0',
                            }} />
                        </button>
                        <div style={{ flexGrow: 1.5 }} />
                        <button onClick={() => {
                            if (props.videoID !== 1) {
                                //props.setFrameID(0);
                                props.setVideoID(1);
                                props.loadImages(1, props.setImages);
                            }
                        }} className="scene-selection-buttons">
                            <img className="scene-select-image" src={props.rgbVideos[1][0]} alt="2nd vid" style={{
                                outlineStyle: 'solid',
                                outlineColor: '#20C1BB',
                                outlineWidth: (props.videoID === 1) ? (((window.innerWidth / window.innerHeight) < (299/211)) ? '1vw' : '1vh') : '0',
                            }} />
                        </button>
                        <div style={{ flexGrow: 1.5 }} />
                        <button onClick={() => {
                            if (props.videoID !== 2) {
                                //props.setFrameID(0);
                                props.setVideoID(2);
                                props.loadImages(2, props.setImages);
                            }
                        }} className="scene-selection-buttons">
                            <img className="scene-select-image" src={props.rgbVideos[2][0]} alt="3rd vid" style={{
                                outlineStyle: 'solid',
                                outlineColor: '#20C1BB',
                                outlineWidth: (props.videoID === 2) ? (((window.innerWidth / window.innerHeight) < (299 / 211)) ? '1vw' : '1vh') : '0',
                            }} />
                        </button>
                        <div style={{ flexGrow: 1 }} />
                        <button onClick={handleForwardSelect} className="scene-selection-buttons">
                            <div className="arrow">
                                <img className="arrow-image" src={"./images/ForwardArrow.svg"} alt="right arrow"/>
                            </div>
                        </button>
                    </div>
                    <div className="filler hide-in-portrait" />
                </div>
            </div>:''}

            {props.graphState.currentNode==='dd'?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "dd" ? 'flex' : 'none',
                display: props.graphState.currentNode === "dd" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "dd" ? '-webkit-flex' : 'none',
                flexDirection: 'column',
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p style={{
                        fontSize: '2.5rem',
                        marginTop: '0rem',
                        marginBottom: '1rem',
                    }}>{props.titles.dd}</p>
                </div>

                <div className="each-image" style={{position: 'relative'}}>
                    <AutoPlay show={props.graphState.currentNode === "dd" && props.resID === 0} src={'./images/videos/DownscaledVideo' + (props.videoID + 1) + '_Res0_mid.mp4'} imageSrc={'./images/DownscaledVideo' + (props.videoID + 1) + '_Res0/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <AutoPlay show={props.graphState.currentNode === "dd" && props.resID === 1} src={'./images/videos/DownscaledVideo' + (props.videoID + 1) + '_Res1_mid.mp4'} imageSrc={'./images/DownscaledVideo' + (props.videoID + 1) + '_Res1/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <AutoPlay show={props.graphState.currentNode === "dd" && props.resID === 2} src={'./images/videos/DownscaledVideo' + (props.videoID + 1) + '_Res2_mid.mp4'} imageSrc={'./images/DownscaledVideo' + (props.videoID + 1) + '_Res2/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <div style={{ width: '100%', position: 'absolute' }}>
                        <Legend nodeName={"dd"} side={""} tour={props.tour} orientation={props.orientation} metresText={props.panelTexts.extras.metres} mainText={props.panelTexts.dd.legend} />
                    </div>
                </div>
                {/*props.images.isLoading ? <LoadingIndicator /> :
                    props.images.images["dd"].map((value2, resIndex) => {
                        return (
                            value2.map((value, index) => {
                                return (
                                    <div key={index} style={{
                                        display: index === props.frameID && resIndex === props.resID ? '' : 'none',
                                    }}>
                                        <div className="image-container">
                                            <img className="each-image" src={value.config.url} alt={index} />
                                        </div>
                                    </div>
                                )
                            })
                        )
                    })
                */}
                <InputDepthText onTour={!props.completed} imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} tour={props.tour} resID={props.resID} handleSliderChange={handleSliderChange} text={props.panelTexts.dd} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video} canToggleImages={props.canToggleImages}/>
            </div>:''}

            {props.graphState.currentNode==='rgb'?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "rgb" ? 'flex' : 'none',
                display: props.graphState.currentNode === "rgb" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "rgb" ? '-webkit-flex' : 'none',
                flexDirection: 'column'
            }}>
                <div className="above-image-div">
                    <div className="filler" />
                    <p style={{
                        marginTop: '0rem',
                        marginBottom: '1rem',
                        fontSize: '2.5rem',
                    }}>{props.titles.rgb}</p>
                    <div className="filler hide-in-landscape" />
                </div>

                <AutoPlay show={props.graphState.currentNode === 'rgb'} src={'./images/videos/RGBVideo' + (props.videoID + 1) + '_mid.mp4'} imageSrc={'./images/RGBVideo' + (props.videoID + 1) + '/Frame1.jpg'} imageOnly={props.imageOnly} />
                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} canToggleImages={props.canToggleImages} tour={props.tour} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video} />
                        
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.rgb.p1}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.rgb.p2}
                    </p>
                </div>
            </div>:''}

            {props.graphState.currentNode==='pd'?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "pd" ? 'flex' : 'none',
                display: props.graphState.currentNode === "pd" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "pd" ? '-webkit-flex' : 'none',
                flexDirection: 'column'
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p style={{
                        fontSize: '2.5rem',
                        marginTop: '0rem',
                        marginBottom: '1rem',
                    }}>{props.titles.pd}</p>
                </div>

                <div style={{position: 'relative'}}>
                    <AutoPlay show={props.graphState.currentNode === "pd" && props.resID === 0} src={'./images/videos/DepthPredVideo' + (props.videoID + 1) + '_Res0_mid.mp4'} imageSrc={'./images/DepthPredVideo' + (props.videoID + 1) + '_Res0/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <AutoPlay show={props.graphState.currentNode === "pd" && props.resID === 1} src={'./images/videos/DepthPredVideo' + (props.videoID + 1) + '_Res1_mid.mp4'} imageSrc={'./images/DepthPredVideo' + (props.videoID + 1) + '_Res1/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <AutoPlay show={props.graphState.currentNode === "pd" && props.resID === 2} src={'./images/videos/DepthPredVideo' + (props.videoID + 1) + '_Res2_mid.mp4'} imageSrc={'./images/DepthPredVideo' + (props.videoID + 1) + '_Res2/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <div style={{ width: '100%', position: 'absolute' }}>
                        <Legend nodeName={"pd"} side={""} tour={props.tour} orientation={props.orientation} metresText={props.panelTexts.extras.metres} mainText={props.panelTexts.pd.legend}/>
                    </div>
                </div>

                <PredictedDepthText onTour={!props.completed} imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} tour={props.tour} text={props.panelTexts.pd} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video} canToggleImages={props.canToggleImages} />
            </div>:''}

            {props.graphState.currentNode==='prec'?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "prec" ? 'flex' : 'none',
                display: props.graphState.currentNode === "prec" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "prec" ? '-webkit-flex' : 'none',
                flexDirection: 'column'
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p style={{
                        fontSize: '2.5rem',
                        marginTop: '0rem',
                        marginBottom: '1rem',
                    }}>{props.titles.prec}</p>
                </div>

                <div style={{position: 'relative'}}>
                    <AutoPlay show={props.graphState.currentNode === "prec" && props.resID === 0} src={'./images/videos/PrecVideo' + (props.videoID + 1) + '_Res0_mid.mp4'} imageSrc={'./images/PrecVideo' + (props.videoID + 1) + '_Res0/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <AutoPlay show={props.graphState.currentNode === "prec" && props.resID === 1} src={'./images/videos/PrecVideo' + (props.videoID + 1) + '_Res1_mid.mp4'} imageSrc={'./images/PrecVideo' + (props.videoID + 1) + '_Res1/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <AutoPlay show={props.graphState.currentNode === "prec" && props.resID === 2} src={'./images/videos/PrecVideo' + (props.videoID + 1) + '_Res2_mid.mp4'} imageSrc={'./images/PrecVideo' + (props.videoID + 1) + '_Res2/Frame1.jpg'} imageOnly={props.imageOnly} />
                    <div style={{ width: '100%', position: 'absolute' }}>
                        <Legend nodeName={"prec"} side={""} tour={props.tour} orientation={props.orientation} metresText={props.panelTexts.extras.metres} mainText={props.panelTexts.prec.legend}/>
                    </div>
                </div>
                {/*props.images.isLoading ? <LoadingIndicator /> :
                    props.images.images["prec"].map((value2, resIndex) => {
                        return (
                            value2.map((value, index) => {
                                return (
                                    <div key={index} style={{
                                        display: index === props.frameID && resIndex === props.resID ? '' : 'none',
                                    }}>
                                        <div className="image-container">
                                            <img className="each-image" src={value.config.url} alt={index} />
                                        </div>
                                    </div>
                                )
                            })
                        )
                    })
                */}

                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} canToggleImages={props.canToggleImages} tour={props.tour} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video}/>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.prec.p1}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.prec.p2}
                    </p>
                </div>
            </div>:''}

            {props.graphState.currentNode==='comp'?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "comp" ? 'flex' : 'none',
                display: props.graphState.currentNode === "comp" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "comp" ? '-webkit-flex' : 'none',
                flexDirection: 'column'
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p style={{
                        fontSize: '2.5rem',
                        marginTop: '0rem',
                        marginBottom: '1rem',
                    }}>{props.titles.comp}</p>
                </div>


                <ComparisonWidget {...props}
                    imageOnly={props.imageOnly}
                    leftText={props.panelTexts.comp.t1}
                    rightText={props.panelTexts.comp.t2}
                    leftName={"dd"}
                    leftImages={'Downscaled'}
                    rightName={"pd"}
                    rightImages={'DepthPred'}
                    frameTime={props.frameTime}
                    show={props.graphState.currentNode === "comp"}
                    lineColor={"white"}
                />

                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} canToggleImages={props.canToggleImages} tour={props.tour} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video}/>
                    <p style={{
                        lineHeight: '1.8',
                        fontFamily: 'Roboto, Noto Sans JP', fontWeight:"900",
                    }}>
                        {props.panelTexts.comp.p1}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.comp.p2}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.comp.p3}
                    </p>
                </div>
            </div>:''}

            {props.graphState.currentNode?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "diff" ? 'flex' : 'none',
                display: props.graphState.currentNode === "diff" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "diff" ? '-webkit-flex' : 'none',
                flexDirection: 'column'
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p style={{
                        fontSize: '2.5rem',
                        marginTop: '0rem',
                        marginBottom: '1rem',
                    }}>{props.titles.diff}</p>
                </div>

                <ComparisonWidget {...props}
                    imageOnly={props.imageOnly}
                    leftText={props.panelTexts.diff.t1}
                    rightText={props.panelTexts.diff.t2}
                    leftName={"prec"} rightName={"diff"}
                    leftImages={'Prec'} rightImages={'Diff'}
                    frameTime={props.frameTime}
                    show={props.graphState.currentNode === "diff"}
                    lineColor={"black"}
                />

                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} canToggleImages={props.canToggleImages} tour={props.tour} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video}/>
                    <p style={{
                        lineHeight: '1.8',
                        fontFamily: 'Roboto, Noto Sans JP', fontWeight:"900",
                    }}>
                        {props.panelTexts.diff.p1}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.diff.p2}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.diff.p3}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.diff.p4}
                    </p>
                </div>
            </div>:''}

            {props.graphState.currentNode==='td'?<div style={{
                alignItems: 'center',
                display: props.graphState.currentNode === "td" ? 'flex' : 'none',
                display: props.graphState.currentNode === "td" ? '-ms-flexbox' : 'none',
                display: props.graphState.currentNode === "td" ? '-webkit-flex' : 'none',
                flexDirection: 'column'
            }}>
                <div className="above-image-div">
                    <div style={{ flexGrow: 1 }} />
                    <p style={{
                        fontSize: '2.5rem',
                        marginTop: '0rem',
                        marginBottom: '1rem',
                    }}>{props.titles.td}</p>
                </div>

                <ComparisonWidget {...props}
                    imageOnly={props.imageOnly}
                    leftText={props.panelTexts.td.t1}
                    rightText={props.panelTexts.td.t2}
                    rightName={"pd2"}
                    rightImages={"DepthPred"}
                    leftName={"td"}
                    leftImages={"DepthTruth"} leftSimple={true}
                    frameTime={props.frameTime}
                    show={props.graphState.currentNode === "td"}
                    lineColor={"white"}
                />

                <div className="below-image-div">
                    <ImageVideoSelector imageOnly={props.imageOnly} setImageOnly={props.setImageOnly} canToggleImages={props.canToggleImages} tour={props.tour} imageT={props.panelTexts.extras.image} videoT={props.panelTexts.extras.video}/>
                    <p style={{
                        lineHeight: '1.8',
                        fontFamily: 'Roboto, Noto Sans JP', fontWeight:"900",
                    }}>
                        {props.panelTexts.td.p1}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.td.p2}
                    </p>
                    <p style={{
                        lineHeight: '1.8',
                    }}>
                        {props.panelTexts.td.p3}
                    </p>
                </div>
            </div>:''}
        </div>}
    </>)
}

export default Panels;
