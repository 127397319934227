import React from 'react';
import { Link } from 'react-router-dom';
import "./intro.css";
import LocalizedStrings from 'react-localization';

/* eslint-disable no-dupe-keys */

function Introduction(props) {

    const strings = new LocalizedStrings({
        en: {
            mobilityLink: props.text_data["mobilityLink"][0],
            title: props.text_data["title"][0],
            p1: props.text_data["p1"][0],
            p2: props.text_data["p2"][0],
            p3: props.text_data["p3"][0],
            p4: props.text_data["p4"][0],
            button: props.text_data["button"][0],
        },
        ja: {
            mobilityLink: props.text_data["mobilityLink"][1],
            title: props.text_data["title"][1],
            p1: props.text_data["p1"][1],
            p2: props.text_data["p2"][1],
            p3: props.text_data["p3"][1],
            p4: props.text_data["p4"][1],
            button: props.text_data["button"][1],
        }
    })

    //strings.setLanguage("ja");

    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'grid',
            overflow: 'hidden',
        }}>
            <div style={{
                height: '100vh',
                width: '100vw',
                backgroundColor: '#28334D',
                gridColumn: 1,
                gridRow: 1,
            }}>
                <img src="./images/Intro Background.svg" alt="" style={{
                    height: '100vh',
                }} />
            </div>
            <div style={{
                height: 'calc(100vh)',
                width: 'calc(100vw-4rem)',
                //padding: '0rem 2rem',
                display: '-webkit-box',
                display: '-moz-box',
                display: '-ms-flexbox',
                display: '-webkit-flex',
                display: 'flex',
                gridColumn: 1,
                gridRow: 1,
            }}>
                <div className='hide-in-small'>
                    <div style={{
                        display: '-webkit-box',
                        display: '-moz-box',
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex', flexDirection: 'column'
                    }}>
                        <a href={strings.mobilityLink} target="noopener noreferrer">
                            <div style={{ height: '44px', marginTop: '1rem', marginLeft: '1rem' }}>
                                <img src="./images/cc-logo.svg" alt="CC Logo" style={{
                                    width: "auto",
                                }} />
                            </div>
                        </a>
                        <a href="/" className="home-link">
                            <p>{"EnfuseNet / " + strings.title}</p>
                        </a>
                    </div>
                </div>
                <div style={{ height: '4vh' }} />
                <div style={{
                    display: '-webkit-box',
                    display: '-moz-box',
                    display: '-ms-flexbox',
                    display: '-webkit-flex',
                    display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div className="filler" />
                    <div className="intro-content">
                        <div style={{
                            display: '-webkit-box',
                            display: '-moz-box',
                            display: '-ms-flexbox',
                            display: '-webkit-flex',
                            display: 'flex',
                            flexDirection: 'row',
                            height: '90%'
                        }}>
                            <div className="left-filler" />
                            <div className="text-container">
                                <div className="top-filler" />
                                <div className="intro-text">
                                    <h1 className="intro-h">{strings.title}</h1>
                                    <p className="intro-p">
                                        {strings.p1}
                                    </p>
                                    <div style={{
                                        height: "1px",
                                    }} />
                                    <p className="intro-p">
                                        {strings.p2}
                                    </p>
                                    <div style={{
                                        height: "1px",
                                    }} />
                                    <p className="intro-p">
                                        {strings.p3}
                                    </p>
                                    <div style={{
                                        height: "1px",
                                    }} />
                                    <p className="intro-p">
                                        {strings.p4}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Link to="/tour" style={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    textDecoration: 'none',
                    fontSize: '1.2rem',
                    textAlign: 'center',
                    width: 'fit-content',
                    padding: '1rem 7rem',
                    fontFamily: 'Roboto, Noto Sans JP', fontWeight:"700",
                    position: 'absolute',
                    right: '4%',
                    marginTop: '1rem',
                    bottom: '4%',
                    borderRadius: "40px",
                    boxShadow: '0 0 20px 5px rgba(0, 0, 0, 0.4)',
                }}>{strings.button}</Link>
            </div>
        </div>
    )
}

export default Introduction;
