import React from 'react';
import { LegendSVG, LegendSVGLog, LegendSVGLog2 } from './LegendSVGs';
import { ddGradient, tdGradient, pdGradient, precGradient, diffGradient } from './LegendGradients';

var gradientDictExplore = {
    "pd": pdGradient(71),
    "pd2": pdGradient(81),
    "dd": ddGradient(73),
    "td": tdGradient(75),
    "diff": diffGradient(77),
    "prec": precGradient(79),
}

var gradientDictTour = {
    "pd": pdGradient(72),
    "pd2": pdGradient(82),
    "dd": ddGradient(74),
    "td": tdGradient(76),
    "diff": diffGradient(78),
    "prec": precGradient(80),
}

function LegendLandscapeRight({ nodeName, tour, mainText, metresText }) {
    switch (nodeName) {
        case 'dd':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["dd"] : gradientDictExplore["dd"]} gradientID={!tour ? "73" : "74"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'td':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["td"] : gradientDictExplore["td"]} gradientID={!tour ? "75" : "76"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
          
            )

        case 'pd':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd"] : gradientDictExplore["pd"]} gradientID={!tour ? "71" : "72"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'pd2':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd2"] : gradientDictExplore["pd2"]} gradientID={!tour ? "81" : "82"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'prec':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog2 numbers={["10.0", "5.0", "1.0", "< 0.5"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["prec"] : gradientDictExplore["prec"]} gradientID={!tour ? "79" : "80"}/>
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'diff':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVG numbers={["10", "5", "0", "-5", "-10"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["diff"] : gradientDictExplore["diff"]} gradientID={!tour ? "77" : "78"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        default:
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >  
            )
    }
}

export default LegendLandscapeRight;
