import React from 'react';
import { LegendSVG, LegendSVGLog, LegendSVGLog2 } from './LegendSVGs';
import { ddGradient, tdGradient, pdGradient, precGradient, diffGradient } from './LegendGradients';

var gradientDictExplore = {
    "pd": pdGradient(1),
    "dd": ddGradient(3),
    "td": tdGradient(5),
    "diff": diffGradient(7),
    "prec": precGradient(9),
}

var gradientDictTour = {
    "pd": pdGradient(2),
    "dd": ddGradient(4),
    "td": tdGradient(6),
    "diff": diffGradient(8),
    "prec": precGradient(10),
}

function LegendLandscape({ nodeName, tour, mainText, metresText }) {
    switch (nodeName) {
        case 'dd':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["dd"] : gradientDictExplore["dd"]} gradientID={!tour ? "3" : "4"} />
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        case 'td':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["td"] : gradientDictExplore["td"]} gradientID={!tour ? "5" : "6"} />
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
          
            )

        case 'pd':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd"] : gradientDictExplore["pd"]} gradientID={!tour ? "1" : "2"} />
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        case 'prec':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog2 numbers={["10.0", "5.0", "1.0", "< 0.5"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["prec"] : gradientDictExplore["prec"]} gradientID={!tour ? "9" : "10"}/>
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        case 'diff':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVG numbers={["10", "5", "0", "-5", "-10"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["diff"] : gradientDictExplore["diff"]} gradientID={!tour ? "7" : "8"} />
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        default:
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >  
            )
    }
}

export default LegendLandscape;
