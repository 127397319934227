import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { detect } from 'detect-browser';
import LocalizedStrings from 'react-localization';
//import compareVersions from 'compare-versions';

/* eslint-disable no-dupe-keys */


function LandingParagraph({ aBool, string1, string2, string3 }) {
    switch (aBool) {
        case true:
            return (
                <p style={{
                    color: 'white',
                    fontSize: '1.5rem',
                }}>
                    {string2} <br />
                    {string3}
                </p>
            )
        case false:
            return (
                <p style={{
                    color: 'white',
                    fontSize: '1.5rem',
                    width: "60vw",
                }}>
                    {string1}
                </p>
            )
        default:
            return (<div />)
    }
}

function handleResize(hook) {
    return () => {
        hook(((window.innerWidth / window.innerHeight) > (299 / 211)));
    }
}

const browser = detect();
console.log(browser)
// const isKnownOs = ['Android OS', 'Windows 10', 'MacOS', 'iOS'].includes(browser.os);
// const isKnownBrowser =
//     (browser.name === 'chrome' && compareVersions(browser.version, '78.0.0') >= 0)
//     ||
//     (browser.name === 'firefox' && compareVersions(browser.version, '77.0.0') >= 0)
//     ||
//     (browser.name === 'safari' && compareVersions(browser.version, '13.0.0') >= 0)
//     ||
//     (browser.name === 'edge' && compareVersions(browser.version, '18.0.0') >= 0)
//     ||
//     (browser.name === 'ios' && compareVersions(browser.version, '13.0.0') >= 0)

// if (!isKnownOs || !isKnownBrowser) {
//     const errorText = 'For the best experience' +
//         (isKnownBrowser ? '' : ', please use the latest chrome / firefox / edge / safari')
//         + (isKnownOs ? '' : ', please access from Windows 10, MacOS, iOS or Android') 
//     toast.info(errorText, {
//         position: toast.POSITION.TOP_RIGHT,
//         hideProgressBar: true,
//         closeOnClick: false,
//         pauseOnHover: false,
//         draggable: false,
//         progress: undefined,
//     });
// }

function Landing(props) {

    const strings = new LocalizedStrings({
        en:{
            mobilityLink: props.text_data["mobilityLink"][0],
            breacrumb: props.text_data["title"][0],
            title: props.text_data["t1"][0],
            subtitle: props.text_data["t2"][0],
            p1: props.text_data["p1"][0],
            p2: props.text_data["p2"][0],
            p3: props.text_data["p3"][0],
            button: props.text_data["button"][0],
        },
        ja:{
            mobilityLink: props.text_data["mobilityLink"][1],
            breacrumb: props.text_data["title"][1],
            title: props.text_data["t1"][1],
            subtitle: props.text_data["t2"][1],
            p1: props.text_data["p1"][1],
            p2: props.text_data["p2"][1],
            p3: props.text_data["p3"][1],
            button: props.text_data["button"][1],
        }
    })

    //strings.setLanguage("ja");

    const [windowSize, setWindowSize] = useState(false)

    var dummy = 1;

    useEffect(() => {
        window.addEventListener('resize', handleResize(setWindowSize));
    }, [dummy])

    return (
        <div style={{
            //backgroundColor: '#28334D',
            backgroundImage: 'url(./images/background_landing.png)',
            backgroundSize: 'cover',
            backgroundPosition: '50%',
            backgroundBlendMode: 'multiply',
            backgroundColor: 'rgba(90, 90, 90, 1)',
            backgroundRepeat: 'no-repeat',
            overflowX: 'auto',
            display: 'flex',
            width: '100vw',
            height: '100vh',
            flexDirection: 'column',
            //padding: '0rem 2rem',
        }}>
            <ToastContainer
                position={toast.POSITION.TOP_RIGHT}
                autoClose={false}
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
            />
            <a href={strings.mobilityLink} target="noopener noreferrer">
                <div style={{ height: '44px', marginTop: '1rem', marginLeft: '1rem' }}>
                    <img src="./images/cc-logo.svg" alt="CC Logo" style={{
                        width: "fit-content",
                    }} />
                </div>  
            </a>
            <p className="home-link">EnfuseNet / {strings.breacrumb} </p>
            <div style={{ height:'1rem' }} />
            <div style={{
                display: '-webkit-box',
                display: '-moz-box',
                display: '-ms-flexbox',
                display: '-webkit-flex',
                display: 'flex',
                flexDirection: 'column',
                flexGrow: '1',
                marginBottom: '3rem',
                marginLeft: '4rem',
            }} >
                <div style={{
                    width: '4rem',
                    height: '4rem',
                    flexGrow: '1',
                }}></div>
                <h1 style={{
                    color: 'white',
                    fontSize: '3rem',
                    marginBottom: '2rem',
                    fontWeight: 900
                }}>{strings.title}</h1>
                <h2 style={{
                    color: 'white',
                    fontSize: '2rem',
                    marginBottom: '1rem',
                    fontFamily: 'Roboto, Noto Sans JP',
                    marginTop: '0',
                    fontWeight: 700,
                }}>{strings.subtitle}</h2>
                <LandingParagraph aBool={windowSize} string1={strings.p1} string2={strings.p2} string3={strings.p3} />
                <Link to="/introduction" style={{
                    backgroundColor: '#ffffff',
                    color: '#000000',
                    textDecoration: 'none',
                    size: 'lg',
                    lineHeight: '300%',
                    fontSize: '1rem',
                    textAlign: 'center',
                    width: '12rem',
                    marginTop: '2rem',
                    fontFamily: 'Roboto, Noto Sans JP',
                    fontWeight: 700,
                    borderRadius: "40px",
                    boxShadow: '0 0 20px 5px rgba(0, 0, 0, 0.4)',
                }}>{strings.button}</Link>
            </div>
        </div>
    )
}

export default Landing;
