import React from 'react';
import { LegendSVG, LegendSVGLog, LegendSVGLog2 } from './LegendSVGs';
import { ddGradient, tdGradient, pdGradient, precGradient, diffGradient } from './LegendGradients';

var gradientDictExplore = {
    "pd": pdGradient(11),
    "dd": ddGradient(13),
    "td": tdGradient(15),
    "diff": diffGradient(17),
    "prec": precGradient(19),
}

var gradientDictTour = {
    "pd": pdGradient(12),
    "dd": ddGradient(14),
    "td": tdGradient(16),
    "diff": diffGradient(18),
    "prec": precGradient(20),
}

function LegendLandscapeLeft({ nodeName, tour, mainText, metresText }) {
    switch (nodeName) {
        case 'dd':
            return (
                <div className="legend legend-left">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["dd"] : gradientDictExplore["dd"]} gradientID={!tour ? "13" : "14"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'td':
            return (
                <div className="legend legend-left">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["td"] : gradientDictExplore["td"]} gradientID={!tour ? "15" : "16"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
          
            )

        case 'pd':
            return (
                <div className="legend legend-left">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd"] : gradientDictExplore["pd"]} gradientID={!tour ? "11" : "12"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'prec':
            return (
                <div className="legend legend-left">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog2 numbers={["10.0", "5.0", "1.0", "< 0.5"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["prec"] : gradientDictExplore["prec"]} gradientID={!tour ? "19" : "20"}/>
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'diff':
            return (
                <div className="legend legend-left">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVG numbers={["10", "5", "0", "-5", "-10"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["diff"] : gradientDictExplore["diff"]} gradientID={!tour ? "17" : "18"}/>
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        default:
            return (
                <div className="legend legend-left">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >  
            )
    }
}

export default LegendLandscapeLeft;
