import React, { useState, useEffect } from 'react';
import Slider from '@material-ui/core/Slider';
import { Link } from 'react-router-dom';
import Graph2 from './Graph2';
import Panels from './Panels';
import "../results.css";
import useInterval from './UseInterval'
import ReactGA from 'react-ga';
import LocalizedStrings from 'react-localization';

/* eslint-disable no-dupe-keys */

const link = (i, node, strings) => {
    if (i === 0)
        return <div className="instructions">{strings.tour}</div>
    if (i === 1)
        return <div className="instructions">{strings.rgb}</div>
    if (i === 2)
        return <div className="instructions">{strings.dd}</div>
    if (i === 3)
        return <div className="instructions">{strings.pd}</div>
    if (i === 4)
        return <div className="instructions">{strings.prec}</div>
    if (i === 5)
        return <div className="instructions">{strings.comp}</div>
    if (node === 'scene')
        return <div className="instructions">{strings.scene}</div>
    return <Link to='/learnmore' className="contact-button" style={{
        width: 'fit-content',
    }}
    onClick={() => ReactGA.event({
              category: "navigation",
              action: "learn more",
            })
    }
    >{strings.button}</Link>
}

function Breadcrumb({ aBool, text }) {
    switch (!aBool) {
        case false:
            return (
                <p style={{marginLeft: '1rem', height: '1rem'}} className="breadcrumb">EnfuseNet / {text}</p>
            )
        case true:
            return (
                <p style={{ marginLeft: '1rem' }} className="breadcrumb">EnfuseNet</p>
            )
        default:
            return (<div />)
    }
}

function handleResize(hook) {
    return () => hook(((window.innerWidth / window.innerHeight) > (299 / 211)));
}

function Results(props) {

    const handleSliderChange = (event, value) => {
        props.setResID(2-value);
    };

    const [windowSize, setWindowSize] = useState(((window.innerWidth / window.innerHeight) > (299 / 211)))

    useEffect(() => {
        window.addEventListener('resize', handleResize(setWindowSize));
    }, [])

    const {frameID, videoLength, setFrameID, frameTime} = props;

    useInterval(() => {
        if (frameID + 1 < videoLength) {
            setFrameID(frameID + 1);
        } else {
            setFrameID(0);
        }
    }, frameTime);

    const GetTime = () => {
        var d = new Date();
        return d.getTime();
    }

    const graphBottomString = new LocalizedStrings({
        en: {
            tour: props.text_data["tour"]["bottom"][0],
            rgb: props.text_data["rgb"]["bottom"][0],
            dd: props.text_data["dd"]["bottom"][0],
            pd: props.text_data["pd"]["bottom"][0],
            prec: props.text_data["prec"]["bottom"][0],
            comp: props.text_data["comp"]["bottom"][0],
            scene: props.text_data["scene"]["bottom"][0],
            button: props.text_data["extras"]["button"][0],
        },
        ja: {
            tour: props.text_data["tour"]["bottom"][1],
            rgb: props.text_data["rgb"]["bottom"][1],
            dd: props.text_data["dd"]["bottom"][1],
            pd: props.text_data["pd"]["bottom"][1],
            prec: props.text_data["prec"]["bottom"][1],
            comp: props.text_data["comp"]["bottom"][1],
            scene: props.text_data["scene"]["bottom"][1],
            button: props.text_data["extras"]["button"][1],
        }
    })

    const graphTitleStrings = new LocalizedStrings({
        en: {
            mobilityLink: props.text_data["mobilityLink"][0],
            begin: props.text_data["tour"]["title"][0],
            rgb: props.text_data["rgb"]["title"][0],
            dd: props.text_data["dd"]["title"][0],
            proc: props.text_data["proc"]["title"][0],
            algo: props.text_data["algo"]["title"][0],
            pd: props.text_data["pd"]["title"][0],
            pd2: props.text_data["pd"]["title"][0],
            prec: props.text_data["prec"]["title"][0],
            comp: props.text_data["comp"]["title"][0],
            td: props.text_data["td"]["title"][0],
            diff: props.text_data["diff"]["title"][0],
            scene: props.text_data["scene"]["title"][0],
            tour: props.text_data["tour"]["title"][0],
        },
        ja: {
            mobilityLink: props.text_data["mobilityLink"][1],
            begin: props.text_data["tour"]["title"][1],
            rgb: props.text_data["rgb"]["title"][1],
            dd: props.text_data["dd"]["title"][1],
            proc: props.text_data["proc"]["title"][1],
            algo: props.text_data["algo"]["title"][1],
            pd: props.text_data["pd"]["title"][1],
            pd2: props.text_data["pd"]["title"][1],
            prec: props.text_data["prec"]["title"][1],
            comp: props.text_data["comp"]["title"][1],
            td: props.text_data["td"]["title"][1],
            diff: props.text_data["diff"]["title"][1],
            scene: props.text_data["scene"]["title"][1],
            tour: props.text_data["tour"]["title"][1],
        }
    })

    const panelTexts = {
        'tour': new LocalizedStrings({
            en: {
                p1: props.text_data["tour"]["p1"][0],
                p2: props.text_data["tour"]["p2"][0],
                p3: props.text_data["tour"]["p3"][0],
            },
            ja: {
                p1: props.text_data["tour"]["p1"][1],
                p2: props.text_data["tour"]["p2"][1],
                p3: props.text_data["tour"]["p3"][1],
            }
        }),
        'scene': new LocalizedStrings({
            en: {
                p1: props.text_data["scene"]["p1"][0],
                p2: props.text_data["scene"]["p2"][0],
            },
            ja: {
                p1: props.text_data["scene"]["p1"][1],
                p2: props.text_data["scene"]["p2"][1],
            }
        }),
        'rgb': new LocalizedStrings({
            en: {
                p1: props.text_data["rgb"]["p1"][0],
                p2: props.text_data["rgb"]["p2"][0],
            },
            ja: {
                p1: props.text_data["rgb"]["p1"][1],
                p2: props.text_data["rgb"]["p2"][1],
            }
        }),
        'dd': new LocalizedStrings({
            en: {
                p1_1: props.text_data["dd"]["p1_1"][0],
                p2_1: props.text_data["dd"]["p2_1"][0],
                p1_2: props.text_data["dd"]["p1_2"][0],
                p2_2: props.text_data["dd"]["p2_2"][0],
                p3_2: props.text_data["dd"]["p3_2"][0],
                e1: props.text_data["dd"]["e1"][0],
                e2: props.text_data["dd"]["e2"][0],
                e3: props.text_data["dd"]["e3"][0],
                r1: props.text_data["dd"]["r1"][0],
                r2: props.text_data["dd"]["r2"][0],
                r3: props.text_data["dd"]["r3"][0],
                c1: props.text_data["dd"]["c1"][0],
                c2: props.text_data["dd"]["c2"][0],
                c3: props.text_data["dd"]["c3"][0],
                r1_small: props.text_data["dd"]["r1_small"][0],
                r3_small: props.text_data["dd"]["r3_small"][0],
                legend: props.text_data["dd"]["title"][0],
            },
            ja: {
                p1_1: props.text_data["dd"]["p1_1"][1],
                p2_1: props.text_data["dd"]["p2_1"][1],
                p1_2: props.text_data["dd"]["p1_2"][1],
                p2_2: props.text_data["dd"]["p2_2"][1],
                p3_2: props.text_data["dd"]["p3_2"][1],
                e1: props.text_data["dd"]["e1"][1],
                e2: props.text_data["dd"]["e2"][1],
                e3: props.text_data["dd"]["e3"][1],
                r1: props.text_data["dd"]["r1"][1],
                r2: props.text_data["dd"]["r2"][1],
                r3: props.text_data["dd"]["r3"][1],
                c1: props.text_data["dd"]["c1"][1],
                c2: props.text_data["dd"]["c2"][1],
                c3: props.text_data["dd"]["c3"][1],
                r1_small: props.text_data["dd"]["r1_small"][1],
                r3_small: props.text_data["dd"]["r3_small"][1],
                legend: props.text_data["dd"]["title"][1],
            },

        }),
        'proc': new LocalizedStrings({
            en: {
                p1: props.text_data["proc"]["p1"][0],
                p2: props.text_data["proc"]["p2"][0],
            },
            ja: {
                p1: props.text_data["proc"]["p1"][1],
                p2: props.text_data["proc"]["p2"][1],
            }
        }),
        'pd': new LocalizedStrings({
            en: {
                p1_1: props.text_data["pd"]["p1_1"][0],
                p2_1: props.text_data["pd"]["p2_1"][0],
                p1_2: props.text_data["pd"]["p1_2"][0],
                p2_2: props.text_data["pd"]["p2_2"][0],
                legend: props.text_data["pd"]["title"][0],
            },
            ja: {
                p1_1: props.text_data["pd"]["p1_1"][1],
                p2_1: props.text_data["pd"]["p2_1"][1],
                p1_2: props.text_data["pd"]["p1_2"][1],
                p2_2: props.text_data["pd"]["p2_2"][1],
                legend: props.text_data["pd"]["title"][1],
            },
        }),
        'prec': new LocalizedStrings({
            en: {
                p1: props.text_data["prec"]["p1"][0],
                p2: props.text_data["prec"]["p2"][0],
                legend: props.text_data["prec"]["title"][0],
            },
            ja: {
                p1: props.text_data["prec"]["p1"][1],
                p2: props.text_data["prec"]["p2"][1],
                legend: props.text_data["prec"]["title"][1],
            }
        }),
        'comp': new LocalizedStrings({
            en: {
                t1: props.text_data["comp"]["t1"][0],
                t2: props.text_data["comp"]["t2"][0],
                p1: props.text_data["comp"]["p1"][0],
                p2: props.text_data["comp"]["p2"][0],
                p3: props.text_data["comp"]["p3"][0],
            },
            ja: {
                t1: props.text_data["comp"]["t1"][1],
                t2: props.text_data["comp"]["t2"][1],
                p1: props.text_data["comp"]["p1"][1],
                p2: props.text_data["comp"]["p2"][1],
                p3: props.text_data["comp"]["p3"][1],
            }
        }),
        'diff': new LocalizedStrings({
            en: {
                t1: props.text_data["diff"]["t1"][0],
                t2: props.text_data["diff"]["t2"][0],
                p1: props.text_data["diff"]["p1"][0],
                p2: props.text_data["diff"]["p2"][0],
                p3: props.text_data["diff"]["p3"][0],
                p4: props.text_data["diff"]["p4"][0],
                legend: props.text_data["extras"]["diff_legend"][0],
            },
            ja: {
                t1: props.text_data["diff"]["t1"][1],
                t2: props.text_data["diff"]["t2"][1],
                p1: props.text_data["diff"]["p1"][1],
                p2: props.text_data["diff"]["p2"][1],
                p3: props.text_data["diff"]["p3"][1],
                p4: props.text_data["diff"]["p4"][1],
                legend: props.text_data["extras"]["diff_legend"][1],
            }
        }),
        'td': new LocalizedStrings({
            en: {
                t1: props.text_data["td"]["t1"][0],
                t2: props.text_data["td"]["t2"][0],
                p1: props.text_data["td"]["p1"][0],
                p2: props.text_data["td"]["p2"][0],
                p3: props.text_data["td"]["p3"][0],
                legend: props.text_data["extras"]["td_legend"][0],
            },
            ja: {
                t1: props.text_data["td"]["t1"][1],
                t2: props.text_data["td"]["t2"][1],
                p1: props.text_data["td"]["p1"][1],
                p2: props.text_data["td"]["p2"][1],
                p3: props.text_data["td"]["p3"][1],
                legend: props.text_data["extras"]["td_legend"][1],
            }
        }),
        'pd2': new LocalizedStrings({
            en: {
                legend: props.text_data["pd"]["title"][0],
            },
            ja: {
                legend: props.text_data["pd"]["title"][1],
            },
        }),
        'extras': new LocalizedStrings({
            en: {
                video: props.text_data["extras"]["video"][0],
                image: props.text_data["extras"]["image"][0],
                metres: props.text_data["extras"]["metres"][0],
            },
            ja: {
                video: props.text_data["extras"]["video"][1],
                image: props.text_data["extras"]["image"][1],
                metres: props.text_data["extras"]["metres"][1],
            }
        }),
    }

    /*graphTitleStrings.setLanguage("ja");
    graphBottomString.setLanguage("ja");
    for (const key of Object.keys(panelTexts)) {
        panelTexts[key].setLanguage("ja");
    }*/

    return (
        <div className="overlord-div" style={{
            backgroundColor: '#28334D',
            display: '-ms-flexbox',
            display: '-webkit-flex',
            display: 'flex',
            width: '100%',
            flexDirection: 'column'
        }}>
            <div className="main-body">
                <a style={{ width: '100%' }} href={graphTitleStrings.mobilityLink} className="hide-in-landscape" target="noopener noreferrer">
                    <div style={{ marginTop: '1rem', height: '44px', marginLeft: '1rem' }}>
                        <img src="./images/cc-logo.svg" alt="CC Logo" style={{
                            width: "fit-content",
                        }} />
                    </div>
                </a>
                <a style={{ width: '100%', marginLeft: '0rem', }} href="/" className="home-link hide-in-landscape">
                    <Breadcrumb aBool={windowSize} text={graphTitleStrings[props.graphState.currentNode]} />
                </a>
                <div className="content">
                    <div className="panels hide-in-landscape">
                        <div className="filler" />
                        <Panels {...props} windowSize={windowSize} orientation={"portrait"} titles={graphTitleStrings} panelTexts={panelTexts} />
                        <div className="filler" />
                    </div>
                    <div className="graph">
                        <a style={{ width: '100%' }} href={graphTitleStrings.mobilityLink} className="hide-in-portrait">
                            <div style={{marginTop: '1rem', height: '3rem', marginLeft: '1rem'}}>
                                <img src="./images/cc-logo.svg" alt="CC Logo" style={{
                                    width: "fit-content",
                                }} />
                            </div>
                        </a>
                        <a style={{ width: '100%', marginLeft: '0rem', }} href="/" className="home-link hide-in-portrait">
                            <Breadcrumb aBool={windowSize} text={graphTitleStrings[props.graphState.currentNode]} />
                        </a>
                        <div className="top-thing hide-in-landscape" />
                        <div className="graph-container" style={{
                            display: '-ms-flexbox',
                            display: '-webkit-flex',
                            display: 'flex',
                            flexDirection: 'column',
                            flexGrow: '1',
                            alignItems: 'center',
                        }}>
                            <div className="graph-inner">
                            <div className="graph-inner-child">
                                <div style={{top: 0, left: 0}}>
                                        <Graph2 {...props} time={GetTime()} graphText={graphTitleStrings}/>
                                </div>
                                <div className='slider-overlay'>
                                    <div className='slider-left-padding'/>
                                        <div style={{
                                            flexDirection: 'column',
                                            display: '-ms-flexbox',
                                            display: '-webkit-flex',
                                            display: 'flex',}}>
                                        <div style={{flexGrow: 1.25}}/>
                                        <Slider
                                            className={['rgb', 'scene'].includes(props.graphState.currentNode) || !props.completed  ? 'graph-slider' : 'graph-slider-dim'}
                                            orientation='vertical'
                                            style={{
                                                height: '4rem',
                                                pointerEvents: 'all',
                                                color: ['rgb', 'scene'].includes(props.graphState.currentNode) || !props.completed  ? 'rgba(255,255,255,0.05)' : 'rgba(255,255,255)',
                                                pointerEvents: ['rgb', 'scene'].includes(props.graphState.currentNode) || !props.completed  ? 'none' : 'auto',
                                                marginRight: 0}}
                                            value={2-props.resID}
                                            marks={[
                                                {
                                                    value: 0,
                                                    label: panelTexts.dd.r3_small
                                                },
                                                {
                                                    value: 1,
                                                    label: ''
                                                },
                                                {
                                                    value: 2,
                                                    label: panelTexts.dd.r1_small
                                                }
                                            ]}
                                            min={0}
                                            max={2}
                                            onChange={handleSliderChange}
                                        />
                                        <div style={{flexGrow: 5.5}}/>
                                    </div>
                                    <div style={{flexGrow: 0.1}}/>
                                </div>
                            </div>
                            <div className="filler" />
                        </div>
                            <div className="filler" />
                            <div className="button-graph-seperator" style={{
                                display: '-ms-flexbox',
                                display: '-webkit-flex',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignContent: 'center',
                            }}>
                                {link(props.position, props.graphState.currentNode, graphBottomString)}
                            </div>
                            <div className="filler" />
                        </div>
                    </div>
                    <div className="filler hide-in-landscape"/>
                    <div className="hide-in-portrait" style={{
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex',
                        flexDirection: 'row',
                    }}>
                        <div className="divider-container">
                            <div className="divider-line" />
                        </div>
                    </div>
                    <div className="panels hide-in-portrait">
                        <div className="filler" />
                            <Panels {...props} windowSize={windowSize} orientation={"landscape"} titles={graphTitleStrings} panelTexts={panelTexts}/>
                        <div className="filler" />
                    </div>
                </div>
            </div>
            <div className="bottom-filler"/>
        </div>
    )
}

export default Results;
