import React from 'react';
import { LegendSVG, LegendSVGLog, LegendSVGLog2 } from './LegendSVGs';
import { ddGradient, tdGradient, pdGradient, precGradient, diffGradient } from './LegendGradients';

var gradientDictExplore = {
    "pd": pdGradient(21),
    "pd2": pdGradient(31),
    "dd": ddGradient(23),
    "td": tdGradient(25),
    "diff": diffGradient(27),
    "prec": precGradient(29),
}

var gradientDictTour = {
    "pd": pdGradient(22),
    "pd2": pdGradient(32),
    "dd": ddGradient(24),
    "td": tdGradient(26),
    "diff": diffGradient(28),
    "prec": precGradient(30),
}

function LegendLandscapeRight({ nodeName, tour, mainText, metresText }) {
    switch (nodeName) {
        case 'dd':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["dd"] : gradientDictExplore["dd"]} gradientID={!tour ? "23" : "24"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'td':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["td"] : gradientDictExplore["td"]} gradientID={!tour ? "25" : "26"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
          
            )

        case 'pd':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd"] : gradientDictExplore["pd"]} gradientID={!tour ? "21" : "22"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'pd2':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd2"] : gradientDictExplore["pd2"]} gradientID={!tour ? "31" : "32"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'prec':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog2 numbers={["10.0", "5.0", "1.0", "< 0.5"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["prec"] : gradientDictExplore["prec"]} gradientID={!tour ? "29" : "30"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        case 'diff':
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVG numbers={["10", "5", "0", "-5", "-10"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["diff"] : gradientDictExplore["diff"]} gradientID={!tour ? "27" : "28"} />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >
            )

        default:
            return (
                <div className="legend legend-right">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <div className="filler" />
                    </div>
                    <div className="legend-right-filler" />
                </div >  
            )
    }
}

export default LegendLandscapeRight;
