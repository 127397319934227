import React from 'react';

const baseWhite = "#ffffff";
const basePurple = "#20C1BB";
const basePurpleDarker = "#2d8c87";
const basePurpleDarkest = "#356966";
const animationLengthHover = 250;
const animationLengthSelect = 500;
const animationLengthNext = 2000;
const animationLengthGrey = 1500;

const smooth = x => (1 - Math.cos(x * Math.PI)) / 2;
const clamp = x => Math.max(0, Math.min(1, x));

const GetTime = () => {
    var d = new Date();
    return d.getTime();
}

function GenerateBottomLine({ x1, y1, x2, y2, strokeWidth, placeholderNode, animationStates, time }) {

    animationStates = animationStates[placeholderNode];

    var greyedTime = animationStates.isGreyed ? (time - animationStates.timeSinceGreyed) : (animationLengthGrey + (animationStates.timeSinceGreyed - time));
    var greyedOpa = smooth(clamp(greyedTime / animationLengthGrey));
    var actualGreyedOpa = ((1 - greyedOpa) * 0.95) + 0.05;

    return (
        <line x1={x1} y1={y1} x2={x2} y2={y2} strokeWidth={strokeWidth} stroke={baseWhite} strokeOpacity={actualGreyedOpa} />
    )

}

function GenerateNode({ thisNode, click, hover, x, y, line1, line2, animationStates, time, size, algoAnimating, setGraphState}) {

    animationStates = animationStates[thisNode];

    var opa = animationStates.isSelected ? 1 : 0;

    var hoverTime = animationStates.isHovered ? (time - animationStates.timeSinceHovered) : (animationLengthHover + (animationStates.timeSinceHovered - time));
    var hoverOpa = smooth(clamp(hoverTime / animationLengthHover));
    hoverOpa = animationStates.isGreyed ? 0 : hoverOpa; 

    if (thisNode === "pd") {
        if (algoAnimating) {
            hoverOpa = 0;
        }
    }

    var hoverSize = (hoverOpa * 14) + 31.35;
    var hoverBorder = (hoverOpa * 0.7368) + 4;
    var hoverSizeMini = (hoverOpa * 8) + 22;

    var selectTime = animationStates.isSelected ? (time - animationStates.timeSinceSelected) : (animationLengthSelect + (animationStates.timeSinceSelected - time));
    var selectOpa = smooth(clamp(selectTime / animationLengthSelect));
    var selectSizeInner = selectOpa * 24.75;
    var selectSizeOuter = selectOpa * 33;
    var selectSizeMiniInner = selectOpa * 22;
    var selectSizeMiniOuter = selectOpa * 22.5;
    var selectMiniOpa = selectOpa > 0.99 ? 0 : 1;

    var greyedTime = animationStates.isGreyed ? (time - animationStates.timeSinceGreyed) : (animationLengthGrey + (animationStates.timeSinceGreyed - time));
    var greyedOpa = smooth(clamp(greyedTime / animationLengthGrey));
    //var greyedOpa = animationStates.isGreyed ? 1 : 0;
    var actualGreyedOpa = ((1-greyedOpa) * 0.95) + 0.05;

    const nextSizeFactor = 2.5;
    var nextTime = animationStates.isNext ? (time - animationStates.timeSinceNext) : 0;
    var nextOpa = animationStates.isNext ? 1 * (1 - greyedOpa) : 0;

    if (thisNode === "pd") {
        if (algoAnimating) {
            nextOpa = 0;
        }
    }

    var nextSize1 = nextSizeFactor * (Math.abs(Math.sin((nextTime * Math.PI) / animationLengthNext)) * 12) + hoverSize + hoverBorder;
    var nextSize2 = nextSizeFactor * (Math.abs(Math.sin((nextTime * Math.PI) / animationLengthNext)) * 24) + hoverSize + hoverBorder;
    var nextSize1Mini = nextSizeFactor * (Math.abs(Math.sin((nextTime * Math.PI) / animationLengthNext)) * 8) + hoverSizeMini;
    var nextSize2Mini = nextSizeFactor * (Math.abs(Math.sin((nextTime * Math.PI) / animationLengthNext)) * 16) + hoverSizeMini;
    var nextInnerOpa = 0.7 * nextOpa;
    var nextOuterOpa = 0.45 * nextOpa;
    var miniNextInnerOpa = animationStates.isGreyed ? 0 : nextInnerOpa;
    var miniNextOuterOpa = animationStates.isGreyed ? 0 : nextOuterOpa;

    var hoverOffsetTrue = -(hoverOpa * animationLengthHover);
    var hoverOffsetFalse = (hoverOpa - 1) * animationLengthHover;

    switch (size) {
        case "big":
            return (
                <g id={thisNode}>
                    {
                        //eslint-disable-next-line
                    }<a onClick={(animationStates.isSelected || animationStates.isGreyed) ? () => { return; } : click(thisNode, hoverOffsetFalse, setGraphState)} onMouseEnter={hover(thisNode, true, animationStates.isSelected, hoverOffsetTrue)} onMouseLeave={hover(thisNode, false, animationStates.isSelected, hoverOffsetFalse)} style={{
                        cursor: 'pointer',
                    }}>
                        <g>
                            <circle cx={x} cy={y} r={selectSizeOuter} fill="black" fillOpacity={opa} filter="url(#outer-glow)"></circle>
                            <circle stroke={basePurpleDarkest} strokeWidth="0" strokeLinejoin="square" fill={basePurpleDarkest} fillRule="evenodd" cx={x} cy={y} r={nextSize2} fillOpacity={nextOuterOpa}></circle>
                            <circle stroke={basePurpleDarker} strokeWidth="0" strokeLinejoin="square" fill={basePurpleDarker} fillRule="evenodd" cx={x} cy={y} r={nextSize1} fillOpacity={nextInnerOpa}></circle>
                            <circle stroke={basePurple} strokeWidth={hoverBorder} strokeLinejoin="square" fill={baseWhite} fillRule="evenodd" cx={x} cy={y} r={hoverSize} opacity={actualGreyedOpa} strokeOpacity={actualGreyedOpa}></circle>
                        </g>
                        <g>
                            <circle cx={x} cy={y} r={selectSizeInner} fill="black" fillOpacity={opa} filter="url(#inner-glow)"></circle>
                            <circle cx={x} cy={y} r={selectSizeInner} fill={basePurple} fillOpacity={opa} fillRule="evenodd"></circle>
                        </g>
                    </a>
                    <text fontFamily='Roboto, Noto Sans JP' fontSize="26.4" fontWeight="500" letterSpacing="0.162461555" fill={baseWhite} opacity={(1 - selectOpa) * actualGreyedOpa} textAnchor="middle">
                        <tspan x={x} y={+y + 69}>{line1}</tspan>
                        <tspan x={x} y={+y + 99}>{line2}</tspan>
                    </text>
                    <text fontFamily='Roboto, Noto Sans JP' fontSize="26.4" fontWeight="500" letterSpacing="0.162461555" fill={basePurple} opacity={selectOpa} textAnchor="middle">
                        <tspan x={x} y={+y + 69}>{line1}</tspan>
                        <tspan x={x} y={+y + 99}>{line2}</tspan>
                    </text>
                </g>
            )
        case "small":
            return (
                <g id={thisNode}>
                    {
                        //eslint-disable-next-line
                    }<a onClick={animationStates.isSelected ? () => { return; } : click(thisNode, hoverOffsetFalse, setGraphState)} onMouseEnter={hover(thisNode, true, animationStates.isSelected, hoverOffsetTrue)} onMouseLeave={hover(thisNode, false, animationStates.isSelected, hoverOffsetFalse)} style={{
                        cursor: 'pointer',
                    }}>
                        <g>
                            <circle cx={x} cy={y} r={selectSizeMiniOuter} fill="black" fillOpacity={opa} filter="url(#outer-glow-small)"></circle>
                            <circle stroke={basePurpleDarkest} strokeWidth="0" strokeLinejoin="square" fill={basePurpleDarkest} fillRule="evenodd" cx={x} cy={y} r={nextSize2Mini} fillOpacity={miniNextOuterOpa}></circle>
                            <circle stroke={basePurpleDarker} strokeWidth="0" strokeLinejoin="square" fill={basePurpleDarker} fillRule="evenodd" cx={x} cy={y} r={nextSize1Mini} fillOpacity={miniNextInnerOpa}></circle>
                            <circle stroke={basePurple} strokeWidth="0" strokeLinejoin="square" fill={baseWhite} fillRule="evenodd" cx={x} cy={y} r={hoverSizeMini} opacity={selectMiniOpa * actualGreyedOpa} strokeOpacity={selectMiniOpa*actualGreyedOpa}></circle>
                        </g>
                        <g>
                            <circle cx={x} cy={y} r={selectSizeMiniInner} fill={basePurple} fillOpacity={opa} fillRule="evenodd"></circle>
                        </g>
                        <text fontFamily='Roboto, Noto Sans JP'  fontSize="20" fontWeight="500" letterSpacing="0.162461555" fill={baseWhite} opacity={(1 - selectOpa) * actualGreyedOpa} textAnchor="middle">
                            <tspan x={x} y={+y + 50}>{line1}</tspan>
                            <tspan x={x} y={+y + 80}>{line2}</tspan>
                        </text>
                        <text fontFamily='Roboto, Noto Sans JP'  fontSize="20" fontWeight="500" letterSpacing="0.162461555" fill={basePurple} opacity={selectOpa} textAnchor="middle">
                            <tspan x={x} y={+y + 50}>{line1}</tspan>
                            <tspan x={x} y={+y + 80}>{line2}</tspan>
                        </text>
                    </a>
                </g>    
            )
        default:
            return (
                <g />   
            )
    }
}

const animationTime = 3000
const puzzleSpins = 2
const calcSpinAngle = ({isAnimating, animationStartTime}, time) => isAnimating ?
    - Math.cos(Math.PI * (time - animationStartTime) / animationTime) * 180 * puzzleSpins
    :
    0

function Graph2(props) {
    const isAnimating = props.puzzleAnimationState.isAnimating
    if ( isAnimating &&
        props.puzzleAnimationState.isAnimating &&
        props.puzzleAnimationState.animationStartTime +
        animationTime < props.time ) {
        props.setPuzzleAnimationState({isAnimating: false})
        if (props.puzzleAnimationState.callOnAnimationEnd)
            props.puzzleAnimationState.callOnAnimationEnd()
    }

    var algoAnimationStates = props.graphState.animationStates["algo"];
    var otherGreyedTime = algoAnimationStates.isGreyed ? (props.time - algoAnimationStates.timeSinceGreyed) : (animationLengthGrey + (algoAnimationStates.timeSinceGreyed - props.time));
    var otherGreyedOpa = smooth(clamp(otherGreyedTime / animationLengthGrey));
    var otherOpa = ((1 - otherGreyedOpa) * 0.95) + 0.05;

    const spinAngle = calcSpinAngle(props.puzzleAnimationState, props.time);
    const puzzleBrightness = 0.05 + 0.95 * (1 - Math.cos(spinAngle * 2 * Math.PI / 360)) / 2;

    const precText = props.graphText.prec.split(" ");
    const precText1 = precText.length === 1 ? precText : precText.slice(0, -1).join(" ");
    const precText2 = precText.length === 1 ? "" : precText.slice(-1)[0];

    const pdText = props.graphText.pd.split(" ");
    const pdText1 = pdText.length === 1 ? pdText : pdText.slice(0, -1).join(" ");
    const pdText2 = pdText.length === 1 ? "" : pdText.slice(-1)[0];

    const compText = props.graphText.comp.split(" ");
    const compText1 = compText.length === 1 ? compText : compText.slice(0, -1).join(" ");
    const compText2 = compText.length === 1 ? "" : compText.slice(-1)[0];

    const diffText = props.graphText.diff.split(" ");
    const diffText1 = diffText.length === 1 ? diffText : diffText.slice(0, -1).join(" ");
    const diffText2 = diffText.length === 1 ? "" : diffText.slice(-1)[0];

    const tdText = props.graphText.td.split(" ");
    const tdText1 = tdText.length === 1 ? tdText : tdText.slice(0, -1).join(" ");
    const tdText2 = tdText.length === 1 ? "" : tdText.slice(-1)[0];

    return (
        <div className="svg-container">
            <svg viewBox="0 -20 600 900" xmlns="https://www.w3.org/2000/svg">
                <defs>
                    <filter x="-22.7%" y="-22.7%" width="145.5%" height="145.5%" filterUnits="objectBoundingBox" id="outer-glow">
                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="7" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                        <feColorMatrix values="0 0 0 0 0.13   0 0 0 0 0.76   0 0 0 0 0.74  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                    <filter x="-22.7%" y="-22.7%" width="145.5%" height="145.5%" filterUnits="objectBoundingBox" id="outer-glow-small">
                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="4" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feComposite in="shadowBlurOuter1" in2="SourceAlpha" operator="out" result="shadowBlurOuter1"></feComposite>
                        <feColorMatrix values="0 0 0 0 0.13   0 0 0 0 0.76   0 0 0 0 0.74  0 0 0 1 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>

                    <filter x="-12.1%" y="-12.1%" width="124.2%" height="124.2%" filterUnits="objectBoundingBox" id="inner-glow">
                        <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
                        <feGaussianBlur stdDeviation="2" in="shadowOffsetOuter1" result="shadowBlurOuter1"></feGaussianBlur>
                        <feColorMatrix values="0 0 0 0 1   0 0 0 0 1   0 0 0 0 1  0 0 0 0.5 0" type="matrix" in="shadowBlurOuter1"></feColorMatrix>
                    </filter>
                </defs>
                <g id="graph" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                    <GenerateNode setGraphState={props.setGraphState} size={"big"} current={props.graphState.currentNode} thisNode={"rgb"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'118.075'} y={'201.75'} line1={props.graphText.rgb} line2={''} time={props.time}
                    />

                    <GenerateNode setGraphState={props.setGraphState} size={"big"} current={props.graphState.currentNode} thisNode={"dd"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'458.725'} y={'201.75'} line1={props.graphText.dd} line2={''} time={props.time}
                    />

                    <GenerateNode setGraphState={props.setGraphState} size={"small"} current={props.graphState.currentNode} thisNode={"scene"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'292.3'} y={'43'} line1={props.graphText.scene} line2={''} time={props.time}
                    />

                    <GenerateNode setGraphState={props.setGraphState} size={"big"} current={props.graphState.currentNode} thisNode={"pd"} click={props.puzzleAnimationState.isAnimating ? () => { return; } : algoAnimationStates.isGreyed ? (thisNode, hoverOffsetFalse) => () => { props.setPuzzleAnimationState({ isAnimating: true, animationStartTime: GetTime(), callOnAnimationEnd: props.click(thisNode, hoverOffsetFalse, props.setGraphState) }); } : props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'128.075'} y={'562.55'} line1={pdText1} line2={pdText2} time={props.time} algoAnimating={props.puzzleAnimationState.isAnimating}
                    />

                    <GenerateNode setGraphState={props.setGraphState} size={"big"} current={props.graphState.currentNode} thisNode={"prec"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'458.725'} y={'562.55'} line1={precText1} line2={precText2} time={props.time}
                    />

                    <g id="Algo" transform="translate(229.325000, 347.050000)" fill={baseWhite}>
                        <path transform={"rotate(" + spinAngle + ", 58, 38) "} d="M88.9428571,31.4285714 L84.2285714,31.4285714 L84.2285714,18.8571429 C84.2285714,15.4 81.4,12.5714286 77.9428571,12.5714286 L65.3714286,12.5714286 L65.3714286,7.85714286 C65.3714286,3.52 61.8514286,0 57.5142857,0 C53.1771429,0 49.6571429,3.52 49.6571429,7.85714286 L49.6571429,12.5714286 L37.0857143,12.5714286 C33.6285714,12.5714286 30.8314286,15.4 30.8314286,18.8571429 L30.8314286,30.8 L35.5142857,30.8 C40.1971429,30.8 44,34.6028571 44,39.2857143 C44,43.9685714 40.1971429,47.7714286 35.5142857,47.7714286 L30.8,47.7714286 L30.8,59.7142857 C30.8,63.1714286 33.6285714,66 37.0857143,66 L49.0285714,66 L49.0285714,61.2857143 C49.0285714,56.6028571 52.8314286,52.8 57.5142857,52.8 C62.1971429,52.8 66,56.6028571 66,61.2857143 L66,66 L77.9428571,66 C81.4,66 84.2285714,63.1714286 84.2285714,59.7142857 L84.2285714,47.1428571 L88.9428571,47.1428571 C93.28,47.1428571 96.8,43.6228571 96.8,39.2857143 C96.8,34.9485714 93.28,31.4285714 88.9428571,31.4285714 Z" id="Path" fillRule="nonzero"></path>
                        <text id="Algorithm" fontFamily='Roboto, Noto Sans JP' fontSize="26.4" fontWeight="500" letterSpacing="0.162461555">
                            <tspan x="0.294123002" y="103.925">{props.graphText.algo}</tspan>
                        </text>
                    </g>

                    <g id="lines">
                        <g opacity={isAnimating ? puzzleBrightness : (props.graphState.currentNode === 'rgb' ? 1 : otherOpa)} id="rgb-algo" transform="translate(173.175000, 346.100000) rotate(-270.000000) translate(-119.175000, -356.100000) translate(66.675000, 300.600000)">
                            <polyline id="Path-5" stroke="#FFFFFF" strokeWidth={2 + puzzleBrightness * 4} points="-2.59490207e-12 110.55 93.225 110.55 93.225 11.55"></polyline>
                            <polygon id="Rectangle" fill="#FFFFFF" transform="translate(93.225000, 11.550000) rotate(-135.000000) translate(-93.225000, -11.550000) " points="100.925 3.85 100.925 19.25 85.525 19.25"></polygon>
                        </g>
                        <g opacity={isAnimating ? puzzleBrightness : (props.graphState.currentNode === 'dd' ? 1 : otherOpa)} id="dd-algo" transform="translate(403.625000, 346.100000) scale(-1, 1) rotate(-270.000000) translate(-339.625000, -356.100000) translate(287.125000, 300.600000)">
                            <polyline id="Path-5" stroke="#FFFFFF" strokeWidth={2 + puzzleBrightness * 4} points="0 110.55 93.225 110.55 93.225 11.55"></polyline>
                            <polygon id="Rectangle" fill="#FFFFFF" transform="translate(93.225000, 11.550000) rotate(-135.000000) translate(-93.225000, -11.550000) " points="100.925 3.85 100.925 19.25 85.525 19.25"></polygon>
                        </g>
                        <g opacity={props.graphState.currentNode === 'pd' ? 1 : otherOpa} id="algo-pd" transform="translate(237.525000, 525.350000) scale(-1, 1) rotate(-270.000000) translate(-173.525000, -173.350000) translate(125.025000, 117.850000)">
                            <polyline id="Path-5" stroke="#FFFFFF" strokeWidth="2.2" points="9.78559456e-12 110.55 86.075 110.55 86.075 11.55"></polyline>
                            <polygon id="Rectangle" fill="#FFFFFF" transform="translate(86.075000, 11.550000) rotate(-135.000000) translate(-86.075000, -11.550000) " points="93.775 3.85 93.775 19.25 78.375 19.25"></polygon>
                        </g>
                        <g opacity={props.graphState.currentNode === 'prec' ? 1 : otherOpa} id="algo-prec" transform="translate(348.175000, 525.350000) rotate(-270.000000) translate(-284.175000, -173.350000) translate(235.675000, 117.850000)">
                            <polyline id="Path-5" stroke="#FFFFFF" strokeWidth="2.2" points="-1.78204118e-12 110.55 86.075 110.55 86.075 11.55"></polyline>
                            <polygon id="Rectangle" fill="#FFFFFF" transform="translate(86.075000, 11.550000) rotate(-135.000000) translate(-86.075000, -11.550000) " points="93.775 3.85 93.775 19.25 78.375 19.25"></polygon>
                        </g>
                    </g>

                    <GenerateBottomLine x1={"0"} y1={"720"} x2={"600"} y2={"720"} strokeWidth={"2"} animationStates={props.graphState.animationStates} time={props.time} placeholderNode={"td"} />

                    <GenerateNode setGraphState={props.setGraphState} size={"small"} current={props.graphState.currentNode} thisNode={"comp"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'77.575'} y={'790'} line1={compText1} line2={compText2} time={props.time}
                    />

                    <GenerateNode setGraphState={props.setGraphState} size={"small"} current={props.graphState.currentNode} thisNode={"td"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'292.575'} y={'790'} line1={tdText1} line2={tdText2} time={props.time}
                    />

                    <GenerateNode setGraphState={props.setGraphState} size={"small"} current={props.graphState.currentNode} thisNode={"diff"} click={props.click} hover={props.hover} animationStates={props.graphState.animationStates}
                        x={'507.575'} y={'790'} line1={diffText1} line2={diffText2} time={props.time}
                    />

                    {/*<GenerateBottomLine x1={"0"} y1={"920"} x2={"585"} y2={"920"} strokeWidth={"2"} animationStates={props.graphState.animationStates} time={props.time} placeholderNode={"td"} />*/}

                </g>
            </svg>
        </div>
    )
}

export default Graph2;
