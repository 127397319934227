import React from 'react';

const ddGradient = id => {
    return (
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={"linearGradient-" + id} >
            <stop stopColor="#0A0A0A" offset="0%"></stop>
            <stop stopColor="#B717AA" offset="17.4176292%"></stop>
            <stop stopColor="#2F43CE" offset="25.2663242%"></stop>
            <stop stopColor="#59CBFF" offset="36.4425111%"></stop>
            <stop stopColor="#188436" offset="47.8771992%"></stop>
            <stop stopColor="#FFD72D" offset="66.3850328%"></stop>
            <stop stopColor="#FF8F2E" offset="79.4388106%"></stop>
            <stop stopColor="#C80000" offset="100%"></stop>
        </linearGradient>
    );
}

const tdGradient = id => {
    return (
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={"linearGradient-" + id} >
            <stop stopColor="#0A0A0A" offset="0%"></stop>
            <stop stopColor="#B717AA" offset="17.4176292%"></stop>
            <stop stopColor="#2F43CE" offset="25.2663242%"></stop>
            <stop stopColor="#59CBFF" offset="36.4425111%"></stop>
            <stop stopColor="#188436" offset="47.8771992%"></stop>
            <stop stopColor="#FFD72D" offset="66.3850328%"></stop>
            <stop stopColor="#FF8F2E" offset="79.4388106%"></stop>
            <stop stopColor="#C80000" offset="100%"></stop>
        </linearGradient>
    );
}

const diffGradient = id => {
    return (
        <linearGradient x1="50%" y1="12.2624578%" x2="50%" y2="84.8817568%" id={"linearGradient-" + id} >
            <stop stopColor="#C30706" offset="0%"></stop>
            <stop stopColor="#FFFFFF" offset="48.4548083%"></stop>
            <stop stopColor="#0808EF" offset="100%"></stop>
        </linearGradient>
    );
}

const precGradient = id => {
    return (
        <linearGradient x1="50%" y1="12.2624578%" x2="50%" y2="84.8817568%" id={"linearGradient-" + id} >
            <stop stopColor="#660100" offset="0%"></stop>
            <stop stopColor="#FF1717" offset="48.4548083%"></stop>
            <stop stopColor="#F3F3F8" offset="100%"></stop>
        </linearGradient>
    );
}

const pdGradient = id => {
    return (
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id={"linearGradient-" + id} >
            <stop stopColor="#0A0A0A" offset="0%"></stop>
            <stop stopColor="#B717AA" offset="17.4176292%"></stop>
            <stop stopColor="#2F43CE" offset="25.2663242%"></stop>
            <stop stopColor="#59CBFF" offset="36.4425111%"></stop>
            <stop stopColor="#188436" offset="47.8771992%"></stop>
            <stop stopColor="#FFD72D" offset="66.3850328%"></stop>
            <stop stopColor="#FF8F2E" offset="79.4388106%"></stop>
            <stop stopColor="#C80000" offset="100%"></stop>
        </linearGradient>
    );
}

export {
    ddGradient,
    tdGradient,
    pdGradient,
    precGradient,
    diffGradient,
}