import React from 'react';

function LegendSVG({ numbers, text, gradient, gradientID }) {
    return (
        <div className="the-legend">
            <svg viewBox="-10 -5 168 296" version="1.1" xmlns="https://www.w3.org/2000/svg">
                <defs>
                    {gradient}
                </defs>
                <g id="Group-9" transform="translate(-6, 1.000000)">
                    <g id="Group-5" transform="translate(30.883846, 0.000000)">
                        <rect id="Rectangle" stroke="#444444" fill={"url(#linearGradient-" + gradientID + ")"} x="0.866153933" y="10" width="18" height="272" rx="5"></rect>
                        <text id="2.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="19">{numbers[0]}</tspan>
                        </text>
                        <text id="1.5" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="86.3175">{numbers[1]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="10" x2="62.8661539" y2="10" id="Line" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                        <line x1="42.8661539" y1="77.3175" x2="62.8661539" y2="77.3175" id="Line-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                        <text id="1.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="153.3175">{numbers[2]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="144.3175" x2="62.8661539" y2="144.3175" id="Line-Copy-2" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                        <text id="0.5" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="220.3175">{numbers[3]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="211.3175" x2="62.8661539" y2="211.3175" id="Line-Copy-3" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                        <text id="0.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="287.3175">{numbers[4]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="278.3175" x2="62.8661539" y2="278.3175" id="Line-Copy-4" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                    </g>
                    <text id="(Input-Depth-m)" transform="rotate(-90.000000)" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933" fill="#FFFFFF" textAnchor="middle">
                        <tspan x="-145" y="22">({text})</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

function LegendSVGLog({ numbers, text, gradient, gradientID }) {
    return (
        <div className="the-legend">
            <svg viewBox="-10 -5 168 296" version="1.1" xmlns="https://www.w3.org/2000/svg">
                <defs>
                    {gradient}
                </defs>
                <g id="Group-9" transform="translate(-6, 1.000000)">
                    <g id="Group-5" transform="translate(30.883846, 0.000000)">
                        <rect id="Rectangle" stroke="#444444" fill={"url(#linearGradient-" + gradientID + ")"} x="0.866153933" y="10" width="18" height="272" rx="5"></rect>
                        <text id="2.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="19">{numbers[0]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="10" x2="62.8661539" y2="10" id="Line" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>

                        <text id="1.5" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="55.007">{numbers[1]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="46.007" x2="62.8661539" y2="46.007" id="Line-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>

                        <text id="0.5" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="171.162">{numbers[2]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="162.162" x2="62.8661539" y2="162.162" id="Line-Copy-3" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>

                        <text id="0.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="287.3175">{numbers[3]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="278.3175" x2="62.8661539" y2="278.3175" id="Line-Copy-4" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                    </g>
                    <text id="(Input-Depth-m)" transform="rotate(-90.000000)" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933" fill="#FFFFFF" textAnchor="middle">
                        <tspan x="-145" y="22">({text})</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

function LegendSVGLog2({ numbers, text, gradientID, gradient }) {
    return (
        <div className="the-legend">
            <svg viewBox="-10 -5 168 296" version="1.1" xmlns="https://www.w3.org/2000/svg">
                <defs>
                    {gradient}
                </defs>
                <g id="Group-9" transform="translate(-6, 1.000000)">
                    <g id="Group-5" transform="translate(30.883846, 0.000000)">
                        <rect id="Rectangle" stroke="#444444" fill={"url(#linearGradient-" + gradientID + ")"} x="0.866153933" y="10" width="18" height="272" rx="5"></rect>
                        <text id="2.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="19">{numbers[0]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="10" x2="62.8661539" y2="10" id="Line" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>

                        <text id="1.5" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="80.9194">{numbers[1]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="71.9194" x2="62.8661539" y2="71.9194" id="Line-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>

                        <text id="1.5" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="225.3981">{numbers[2]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="216.3981" x2="62.8661539" y2="216.3981" id="Line-Copy" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>

                        <text id="0.0" fill="#FFFFFF" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933">
                            <tspan x="82.8661539" y="287.3175">{numbers[3]}</tspan>
                        </text>
                        <line x1="42.8661539" y1="278.3175" x2="62.8661539" y2="278.3175" id="Line-Copy-4" stroke="#FFFFFF" strokeWidth="2" strokeLinecap="round"></line>
                    </g>
                    <text id="(Input-Depth-m)" transform="rotate(-90.000000)" fontFamily="Roboto" fontWeight="500" fontSize="20" letterSpacing="0.123076933" fill="#FFFFFF" textAnchor="middle">
                        <tspan x="-145" y="22">({text})</tspan>
                    </text>
                </g>
            </svg>
        </div>
    )
}

export {
    LegendSVG,
    LegendSVGLog,
    LegendSVGLog2,
}