import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import "./contact.css";
import ReactGA from 'react-ga';
import LocalizedStrings from 'react-localization';
//import { Helmet } from 'react-helmet'

/* eslint-disable no-dupe-keys */

function Contact(props) {

    //const [name, setName] = useState("");
    //const [email, setEmail] = useState("");
    //const [message, setMessage] = useState("");

    const formIDs = new LocalizedStrings({
        en: {
            id: "f2794ba7-2066-404f-82a2-811295bde543"
        },
        ja: {
            id: "a40b8a20-a433-45ce-872d-646b2cf410fe"
        }
    })

    useEffect(() => {
        window.hbspt.forms.create({ portalId: "218152", formId: formIDs.id, target: "#embed-form" });
    }, [formIDs.id])

    const privacyTextListEn = props.text_data["privacy"][0].split("privacy statement.");
    const privacyTextListJa = props.text_data["privacy"][1].split(" ")
    const privacyTextListJa2 = privacyTextListJa[2].split("（")

    const strings = new LocalizedStrings({
        en: {
            title: props.text_data["title"][0],
            t1: props.text_data["t1"][0],
            p1: props.text_data["p1"][0],
            t2: props.text_data["t2"][0],
            p2: props.text_data["p2"][0],
            t3: props.text_data["t3"][0],
            privacy1: privacyTextListEn[0],
            privacy2: "privacy statement.",
            privacy3: "",
            share: props.text_data["share"][0],
            button: props.text_data["button"][0],
            mobilityLink: props.text_data["mobilityLink"][0],
        },
        ja: {
            title: props.text_data["title"][1],
            t1: props.text_data["t1"][1],
            p1: props.text_data["p1"][1],
            t2: props.text_data["t2"][1],
            p2: props.text_data["p2"][1],
            t3: props.text_data["t3"][1],
            privacy1: privacyTextListJa[0],
            privacy2: privacyTextListJa[1] + " " + privacyTextListJa2[0],
            privacy3: "（" + privacyTextListJa2[1],
            share: props.text_data["share"][1],
            button: props.text_data["button"][1],
            mobilityLink: props.text_data["mobilityLink"][1],
        }
    })

    //strings.setLanguage("ja");

    return (
        <div style={{
            height: '100vh',
            width: '100vw',
            display: 'grid',
            overflowY: 'scroll',
            overflowX: 'hidden',
        }}>
            <div style={{
                height: '100vh',
                width: '100vw',
                backgroundColor: '#28334D',
                gridColumn: 1,
                gridRow: 1,
                display: '-webkit-box',
                display: '-moz-box',
                display: '-ms-flexbox',
                display: '-webkit-flex',
                display: 'flex',
                flexDirection: 'row',
                position: 'fixed',
                top: '0',
                left: '0',
                zIndex: '-1',

            }}>
                <div className="filler" />
                <img src="./images/Contact_Background_Landscape.svg" className="hide-in-portrait" alt="" style={{
                    height: '100vh',
                }} />
            </div>
            <div className="contact-main-div" style={{
                height: '100vh',
                width: '100vw',
                padding: '0rem 0rem',
                display: '-webkit-box',
                display: '-moz-box',
                display: '-ms-flexbox',
                display: '-webkit-flex',
                display: 'flex',
                flexDirection: 'column',
                gridColumn: 1,
                gridRow: 1,
            }}>
                <div style={{
                    display: '-webkit-box',
                    display: '-moz-box',
                    display: '-ms-flexbox',
                    display: '-webkit-flex',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                    <a href={strings.mobilityLink} target="noopener noreferrer"
                                    onClick={() => ReactGA.event({
                                              category: "contact",
                                              action: "mobility",
                                            })
                                    }
                    >
                        <div style={{ height: '44px', marginTop: '1rem', marginLeft: '1rem' }}>
                            <img src="./images/cc-logo.svg" alt="CC Logo" style={{
                                width: "auto",
                            }} />
                        </div>
                    </a>
                    <a href="/" className="home-link">
                        <p>{"EnfuseNet / " + strings.title}</p>
                    </a>
                </div>
                <div className="contact-content">
                    <h1 className="contact-h">{strings.title}</h1>

                    <div className="dividers" />

                    <p className="contact-p">
                        <a
                                    onClick={() => ReactGA.event({
                                              category: "contact",
                                              action: "mobility",
                                            })
                                    }
                            href={strings.mobility} target="_blank" rel="noopener noreferrer"><em>{strings.t1} &nbsp;</em>{strings.p1}<br /></a>
                    </p>
                    <p className="contact-p">
                        <a
                                    onClick={() => ReactGA.event({
                                              category: "contact",
                                              action: "whitepaper",
                                            })
                                    }
                            href="https://www.cambridgeconsultants.com/insights/whitepaper/ai-driving-seat" target="_blank" rel="noopener noreferrer"><em>{strings.t2} &nbsp;</em>{strings.p2}<br /></a>
                    </p>
                    <p className="contact-p">
                        <em className="no-hyperlink">{strings.t3}</em>
                    </p>

                    <div className="dividers hide-in-portrait" />

                    <div id='embed-form' className="embed-form"/>

                    <div className="dividers hide-in-short" />

                    {/*<p className="privacy-link-p">
                        It's simple - We won't share your name and address with a third party. However, if you would like us to add your details to receive 
                        occasional emails from us, please check the tick box. To find out more, see our <a href="https://www.cambridgeconsultants.com/legals/privacy-statement" target="_blank" rel="noopener noreferrer">privacy statement. </a>
                    </p>*/}

                    <p className="privacy-link-p">
                        {strings.privacy1}
                        <a href="https://www.cambridgeconsultants.com/legals/privacy-statement" target="_blank" rel="noopener noreferrer">
                            {strings.privacy2}
                        </a>
                        {strings.privacy3}
                    </p>

                    <div className="dividers" />

                    <div style={{
                        display: '-webkit-box',
                        display: '-moz-box',
                        display: '-ms-flexbox',
                        display: '-webkit-flex',
                        display: 'flex',
                        flexDirection: 'row',
                        marginBottom: '2vh',
                    }}>
                        <div className="filler" />
                        <div className="linkedIn">
                            <p>
                                <a href="https://twitter.com/intent/tweet?text=%40CambConsultants%20creates%20breakthrough%20%23AI%20sensor%20fusion%20system%20to%20reduce%20the%20cost%20of%20autonomous%20driving%20systems%2E%20Try%20the%20demo%20for%20yourself%20here%3A%20enfusenet%2Ecom%20%23ADAS" target="_blank" rel="noopener noreferrer" 
                                    onClick={() => ReactGA.event({
                                              category: "contact",
                                              action: "twitter",
                                            })
                                    }
                                > 
                                    <img src="/images/Twitter_Logo_Blue.svg" alt="Twitter Logo" style={{
                                    height: '2rem',
                                    transform: 'translateY(0.5rem)',
                                    }} />
                                </a>
                                &nbsp; &nbsp; &nbsp;
                                <a href="https://www.linkedin.com/sharing/share-offsite/?url=https://enfusenet.com" target="_blank" rel="noopener noreferrer"   
                                    onClick={() => ReactGA.event({
                                              category: "contact",
                                              action: "linkedin",
                                            })
                                    }
                                >
                                    <img src="/images/LI-LOGO.png" alt="LinkedIn Logo" style={{
                                        height: '2rem',
                                        transform: 'translateY(0.5rem)',
                                    }} />
                                </a>
                                &nbsp; &nbsp;
                                {strings.share}</p>
                        </div>
                        <div className="filler" />
                        <Link to="/explore" style={{
                            height: 'fit-content',
                            height: '-moz-fit-content',
                            height: '-webkit-fit-content',
                            backgroundColor: '#ffffff',
                            color: '#000000',
                            textDecoration: 'none',
                            fontSize: '1rem',
                            textAlign: 'center',
                            width: 'fit-content',
                            textDecoration: 'none',
                            padding: '2vh 2rem',
                            fontFamily: 'Roboto, Noto Sans JP',
                            fontWeight: '700',
                            borderRadius: "40px",
                            boxShadow: '0 0 20px 5px rgba(0, 0, 0, 0.4)',
                        }}>{strings.button}</Link>
                    </div>
                </div>
            </div> 
        </div>
    )
}

export default Contact;
