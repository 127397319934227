import React, { useState, useEffect } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Landing from './components/Landing';
import Results from './components/Results';
import Contact from './components/Contact';
import Introduction from './components/Introduction';
import axios from 'axios';
import { range, map } from 'ramda';
import { createBrowserHistory } from 'history';

import ReactGA from 'react-ga';
const trackingId = "UA-37266483-2"
ReactGA.initialize(trackingId);

const history = createBrowserHistory();

history.listen(location => {
  ReactGA.set({ page: location.pathname }); // Update the user's current page
  ReactGA.pageview(location.pathname); // Record a pageview for the given page
});

const nextAnimationState = () => ({
    isHovered: false,
    isNext: true,
    isSelected: false,
    isGreyed: false,
    timeSinceHovered: 0,
    timeSinceNext: new Date().getTime(),
    timeSinceSelected: 0,
    timeSinceGreyed: 0,
})

const selectedAnimationState = () => ({
    isHovered: false,
    isNext: false,
    isSelected: true,
    isGreyed: false,
    timeSinceHovered: 0,
    timeSinceNext: 0,
    timeSinceSelected: 0,
    timeSinceGreyed: new Date().getTime(),
}) 

const activeAnimationState = () => ({
    isHovered: false,
    isNext: false,
    isSelected: false,
    isGreyed: false,
    timeSinceHovered: 0,
    timeSinceNext: 0,
    timeSinceSelected: 0,
    timeSinceGreyed: new Date().getTime(),
}) 

const greyedAnimationState = () => ({
    isHovered: false,
    isNext: false,
    isSelected: false,
    isGreyed: true,
    timeSinceHovered: 0,
    timeSinceNext: 0,
    timeSinceSelected: 0,
    timeSinceGreyed: new Date().getTime(),
}) 

const defaultTourAnimationStates = {
    scene: greyedAnimationState(),
    rgb: nextAnimationState(),
    dd: greyedAnimationState(),
    pd: greyedAnimationState(),
    prec: greyedAnimationState(),
    td: greyedAnimationState(),
    diff: greyedAnimationState(),
    comp: greyedAnimationState(),
    algo: greyedAnimationState(),
}

const defaultAnimationStates = {
    scene: selectedAnimationState(),
    rgb: activeAnimationState(),
    dd: activeAnimationState(),
    pd: activeAnimationState(),
    prec: activeAnimationState(),
    td: activeAnimationState(),
    diff: activeAnimationState(),
    comp: activeAnimationState(),
    algo: activeAnimationState(),
}

const GetTime = () => {
    var d = new Date();
    return d.getTime();
}

const order = ["rgb", "dd", "pd", "prec", "comp", "scene", "all"]
var position = 0;

const videoLength = 2;
const frameTime = 50;
const noOfVideos = 3;
const noOfResolutions = 3;

var allImages = [[], [], []]


//RGB VIDEOS
var rgbVideos = []
map(y => {
    var rgbVideoTemp = [];
    map(x => {
        rgbVideoTemp.push('./images/RGBVideo' + (y + 1).toString() + '/Frame' + x.toString() + '.jpg');
        allImages[y].push('./images/RGBVideo' + (y + 1).toString() + '/Frame' + x.toString() + '.jpg');
    }, range(0, videoLength))
    rgbVideos.push(rgbVideoTemp.slice())
}, range(0, noOfVideos))

//TRUE DEPTH VIDEOS
var tdVideos = []
map(y => {
    var tdVideoTemp = [];
    map(x => {
        tdVideoTemp.push('./images/DepthTruthVideo' + (y + 1).toString() + '/Frame' + x.toString() + '.jpg');
        allImages[y].push('./images/DepthTruthVideo' + (y + 1).toString() + '/Frame' + x.toString() + '.jpg');
    }, range(0, videoLength))
    tdVideos.push(tdVideoTemp.slice())
}, range(0, noOfVideos))

//LOW RES VIDEOS
var ddVideos = []
map(y => {
    var ddVideoTemp1 = []
    map(z => {
        var ddVideoTemp2 = [];
        map(x => {
            ddVideoTemp2.push('./images/DownscaledVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
            allImages[z].push('./images/DownscaledVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
        }, range(0, videoLength))
        ddVideoTemp1.push(ddVideoTemp2.slice())
    }, range(0, noOfVideos))
    ddVideos.push(ddVideoTemp1.slice());
}, range(0, noOfResolutions))

//PREDICTED VIDEOS
var pdVideos = []
map(y => {
    var pdVideoTemp1 = []
    map(z => {
        var pdVideoTemp2 = [];
        map(x => {
            pdVideoTemp2.push('./images/DepthPredVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
            allImages[z].push('./images/DepthPredVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
        }, range(0, videoLength))
        pdVideoTemp1.push(pdVideoTemp2.slice())
    }, range(0, noOfVideos))
    pdVideos.push(pdVideoTemp1.slice());
}, range(0, noOfResolutions))

//DIFFERENCE VIDEOs
var diffVideos = []
map(y => {
    var diffVideoTemp1 = []
    map(z => {
        var diffVideoTemp2 = [];
        map(x => {
            diffVideoTemp2.push('./images/DiffVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
            allImages[z].push('./images/DiffVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
        }, range(0, videoLength))
        diffVideoTemp1.push(diffVideoTemp2.slice())
    }, range(0, noOfVideos))
    diffVideos.push(diffVideoTemp1.slice());
}, range(0, noOfResolutions))

//PRECISION VIDEOs
var precVideos = []
map(y => {
    var precVideoTemp1 = []
    map(z => {
        var precVideoTemp2 = [];
        map(x => {
            precVideoTemp2.push('./images/PrecVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
            allImages[z].push('./images/PrecVideo' + (z + 1).toString() + '_Res' + y + '/Frame' + x.toString() + '.jpg');
        }, range(0, videoLength))
        precVideoTemp1.push(precVideoTemp2.slice())
    }, range(0, noOfVideos))
    precVideos.push(precVideoTemp1.slice());
}, range(0, noOfResolutions))

const makePromises = urls => {
    return axios.all(urls.map(url => axios.get(url)));
}

const doPromises = (urls, lastPromise, responses) => {
    if (urls.length === 0) {
        return lastPromise;
    }

    if (lastPromise) {
        // return doPromises(urls.slice(1), lastPromise.then(newResponses => {
        //     responses.push(newResponses);
        //     return makePromises(urls[0]);
        // }), responses);
    }

    return doPromises(urls.slice(1), makePromises(urls[0]), responses);
}

const loadImages = (scenarioNumber, hook) => {
    hook({
        isLoading: true,
        images: {}
    });

    const urls = [
        rgbVideos[scenarioNumber],
        ddVideos[0][scenarioNumber],
        ddVideos[1][scenarioNumber],
        ddVideos[2][scenarioNumber],
        tdVideos[scenarioNumber],
        pdVideos[0][scenarioNumber],
        pdVideos[1][scenarioNumber],
        pdVideos[2][scenarioNumber],
        diffVideos[0][scenarioNumber],
        diffVideos[1][scenarioNumber],
        diffVideos[2][scenarioNumber],
        precVideos[0][scenarioNumber],
        precVideos[1][scenarioNumber],
        precVideos[2][scenarioNumber],
    ]

    var responses = []

    doPromises(urls, null, responses).then(newResponses => {
        responses.push(newResponses);
        const newImages = {
            isLoading: false,
            images: {
                'rgb': responses[0],
                'dd': [responses[1], responses[2], responses[3]],
                'td': responses[4],
                'pd': [responses[5], responses[6], responses[7]],
                'diff': [responses[8], responses[9], responses[10]],
                'prec': [responses[11], responses[12], responses[13]],
            }
        };
        hook(newImages);
    });
}

function App() {
    if (useLocation().pathname === '/explore') {
        position = 6;
    }

    const [videoID, setVideoID] = useState(0);
    const [frameID, setFrameID] = useState(0);
    const [resID, setResID] = useState(2);
    const [graphState, setGraphState] = useState({currentNode: 'scene', animationStates: defaultAnimationStates});
    const [tourGraphState, setTourGraphState] = useState({currentNode: 'begin', animationStates: defaultTourAnimationStates});
    const [completed, setCompleted] = useState(false);
    const [compareSize, setCompareSize] = useState(0);
    const [isDraggingCompare, setIsDraggingCompare] = useState([false]);
    const [spinning, setSpinning] = useState({
        isSpinning: false,
        angle: 0,
    })

    const [puzzleAnimationState, setPuzzleAnimationState] = useState({isAnimating: false, animationStartTime: 0});

    const [hover,] = useState(() => (nodeName, isHovered, isSelected, offset) => () => {
        if (!isSelected || !isHovered) {
            setGraphState((prevGraphState) => {
                prevGraphState.animationStates[nodeName].isHovered = isHovered;
                prevGraphState.animationStates[nodeName].timeSinceHovered = GetTime() + offset;

                return (prevGraphState);
            })
        }
    })

    const [click, ] = useState(
        () => (nodeName, offset, setGraphState) => {
            ReactGA.event({
                  category: "graph",
                  action: nodeName,
                });
            return (
                () => {

                    hover(nodeName, false, true, offset)();

                    if (order[position] === "all") {
                        setGraphState((prevGraphState) => {
                            const prevSelected = prevGraphState.currentNode;
                            prevGraphState.animationStates[prevSelected].isSelected = false;
                            prevGraphState.animationStates[prevSelected].timeSinceSelected = GetTime();
                            prevGraphState.animationStates[nodeName].isSelected = true;
                            prevGraphState.animationStates[nodeName].timeSinceSelected = GetTime();
                            prevGraphState.currentNode = nodeName;
                            return (prevGraphState);
                        })
                    }
                    else if (nodeName === order[position]) {
                        position++;
                        setGraphState((prevGraphState) => {
                            const prevSelected = prevGraphState.currentNode;
                            if (prevGraphState.animationStates[prevSelected]) {
                                prevGraphState.animationStates[prevSelected].isSelected = false;
                                prevGraphState.animationStates[prevSelected].timeSinceSelected = GetTime();
                                prevGraphState.animationStates[prevSelected].isGreyed = true;
                                prevGraphState.animationStates[prevSelected].timeSinceGreyed = GetTime();
                            }
                            prevGraphState.animationStates[nodeName].isSelected = true;
                            prevGraphState.animationStates[nodeName].timeSinceSelected = GetTime();
                            prevGraphState.animationStates[nodeName].isNext = false;
                            prevGraphState.currentNode = nodeName;

                            if (order[position] === "all") {
                                Object.keys(prevGraphState.animationStates).forEach((key, index) => {
                                    prevGraphState.animationStates[key].timeSinceGreyed = prevGraphState.animationStates[key].isGreyed === true ? GetTime() : 0;
                                    prevGraphState.animationStates[key].isGreyed = false;
                                    prevGraphState.animationStates[key].isNext = false;
                                    prevGraphState.animationStates[key].timeSinceNext = GetTime();
                                })
                                setCompleted(true);
                            }
                            else {
                                prevGraphState.animationStates[order[position]].isNext = true;
                                prevGraphState.animationStates[order[position]].timeSinceNext = GetTime();
                                prevGraphState.animationStates[order[position]].isGreyed = false;
                                prevGraphState.animationStates[order[position]].timeSinceGreyed = GetTime();
                            }

                            return (prevGraphState);
                        })
                    }
                }
            )
        }

    )

    const [images, setImages] = useState({
        isLoading: true,
        images: {}
    })

    useEffect(() => {
        loadImages(0, setImages);
    }, [])

    const translation_data = require("./translation.json");

    return (
        <main>
            <Switch>
                <Route path="/" exact >
                    <Landing text_data={translation_data["landing"]} />
                </Route>
                <Route path="/introduction" exact >
                    <Introduction text_data={translation_data["intro"]} />
                </Route>
                <Route path="/tour" exact >
                    <Results frameID={frameID} setFrameID={setFrameID}
                        imageOnly={false} canToggleImages={false}
                        frameTime={frameTime} videoLength={videoLength}
                        videoID={videoID} setVideoID={setVideoID}
                        rgbVideos={rgbVideos} tdVideos={tdVideos}
                        ddVideos={ddVideos} pdVideos={pdVideos}
                        diffVideos={diffVideos} precVideos={precVideos}
                        images={images}
                        loadImages={loadImages} setImages={setImages}
                        resID={resID} setResID={setResID}
                        graphState={tourGraphState} setGraphState={setTourGraphState}
                        tour={true}
                        compareSize={compareSize} setCompareSize={setCompareSize}
                        isDraggingCompare={isDraggingCompare} setIsDraggingCompare={setIsDraggingCompare}
                        completed={completed} setCompleted={setCompleted}
                        click={click} hover={hover}
                        position={position}
                        puzzleAnimationState={puzzleAnimationState} setPuzzleAnimationState={setPuzzleAnimationState}
                        spinning={spinning} setSpinning={setSpinning}
                        text_data={translation_data["main"]} />
                </Route>
                <Route path="/explore" exact >
                    <Results frameID={frameID} setFrameID={setFrameID}
                        imageOnly={false} canToggleImages={true}
                        frameTime={frameTime} videoLength={videoLength}
                        videoID={videoID} setVideoID={setVideoID}
                        rgbVideos={rgbVideos} tdVideos={tdVideos}
                        ddVideos={ddVideos} pdVideos={pdVideos}
                        diffVideos={diffVideos} precVideos={precVideos}
                        images={images}
                        loadImages={loadImages} setImages={setImages}
                        resID={resID} setResID={setResID}
                        graphState={graphState} setGraphState={setGraphState}
                        tour={false}
                        compareSize={compareSize} setCompareSize={setCompareSize}
                        isDraggingCompare={isDraggingCompare} setIsDraggingCompare={setIsDraggingCompare}
                        completed={true} setCompleted={setCompleted}
                        click={click} hover={hover}
                        position={position}
                        puzzleAnimationState={puzzleAnimationState} setPuzzleAnimationState={setPuzzleAnimationState}
                        spinning={spinning} setSpinning={setSpinning}
                        text_data={translation_data["main"]} />
                </Route>
                <Route path="/learnmore" exact >
                    <Contact text_data={translation_data["contact"]}/>
                </Route>
                <Route>
                    <h1 style={{ color: "black" }}>404 - This page does not exist</h1>
                    <a href="/" style={{ color: "black", fontFamily: "Roboto", }}>Click here to return to the EnfuseNet landing page</a>
                </Route>
            </Switch>
        </main>
    )
}

export const vidQuality = 'mid';
export default App;
