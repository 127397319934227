import React from 'react';
import { LegendSVG, LegendSVGLog, LegendSVGLog2 } from './LegendSVGs';
import { ddGradient, tdGradient, pdGradient, precGradient, diffGradient } from './LegendGradients';

var gradientDictExplore = {
    "pd": pdGradient(51),
    "dd": ddGradient(53),
    "td": tdGradient(55),
    "diff": diffGradient(57),
    "prec": precGradient(59),
}

var gradientDictTour = {
    "pd": pdGradient(52),
    "dd": ddGradient(54),
    "td": tdGradient(56),
    "diff": diffGradient(58),
    "prec": precGradient(60),
}

function LegendLandscape({ nodeName, tour, mainText, metresText }) {
    switch (nodeName) {
        case 'dd':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["dd"] : gradientDictExplore["dd"]} gradientID={!tour ? "53" : "54"}/>
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        case 'td':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["td"] : gradientDictExplore["td"]} gradientID={!tour ? "55" : "56"}/>
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
          
            )

        case 'pd':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog numbers={["200", "100", "10", "1"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["pd"] : gradientDictExplore["pd"]} gradientID={!tour ? "51" : "52"}/>
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        case 'prec':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVGLog2 numbers={["10.0", "5.0", "1.0", "< 0.5"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["prec"] : gradientDictExplore["prec"]} gradientID={!tour ? "59" : "60"}/>
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        case 'diff':
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <LegendSVG numbers={["10", "5", "0", "-5", "-10"]} text={mainText + " - " + metresText} gradient={tour ? gradientDictTour["diff"] : gradientDictExplore["diff"]} gradientID={!tour ? "57" : "55"}/>
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >
            )

        default:
            return (
                <div className="legend ">
                    <div className="legend-left-filler" />
                    <div className="legend-column">
                        <div className="filler" />
                        <div className="filler" />
                    </div>
                    <div className="-filler" />
                </div >  
            )
    }
}

export default LegendLandscape;
