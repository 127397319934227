import React from 'react';

export default ({ src, show, imageOnly, imageSrc }) => {
    if (show) {
        console.log(src)
        return imageOnly ?
            <img src={imageSrc} alt='imageSrc' className='each-image' />
            :
            <video className="each-image" autoPlay loop key={src}>
                <source src={src} type="video/mp4"/>
                Your browser does not support the video tag.
            </video>
    }
    return '';
}
