import React from 'react';
import LegendLandscape from './Legends/LegendLandscape';
import LegendLandscapeLeft from './Legends/LegendLandscapeLeft';
import LegendLandscapeRight from './Legends/LegendLandscapeRight';
import LegendPortrait from './Legends/LegendPortrait';
import LegendPortraitLeft from './Legends/LegendPortraitLeft';
import LegendPortraitRight from './Legends/LegendPortraitRight';

function Legend({ nodeName, side, tour, orientation, mainText, metresText }) {
    if (orientation === "landscape") {
        if (side === "right") {
            return (
                <LegendLandscapeRight nodeName={nodeName} tour={tour} metresText={metresText} mainText={mainText} />
            )
        }
        else if (side === "left") {
            return (
                <LegendLandscapeLeft nodeName={nodeName} tour={tour} metresText={metresText} mainText={mainText}/>
            )
        }
        else {
            return (
                <LegendLandscape nodeName={nodeName} tour={tour} metresText={metresText} mainText={mainText}/>
            )
        }
    }
    else if (orientation === "portrait") {
        if (side === "right") {
            return (
                <LegendPortraitRight nodeName={nodeName} tour={tour} metresText={metresText} mainText={mainText}/>
            )
        }
        else if (side === "left") {
            return (
                <LegendPortraitLeft nodeName={nodeName} tour={tour} metresText={metresText} mainText={mainText}/>
            )
        }
        else {
            return (
                <LegendPortrait nodeName={nodeName} tour={tour} metresText={metresText} mainText={mainText}/>
            )
        }
    }
    else {
        return (
            <></>    
        )
    }
}

export default Legend;
